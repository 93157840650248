// Default variables
$font_size: 14px !default;
$font_size_small: 12px !default;

$input_border_radius: 5px !default;
$input_height: 42px !default;
$input_height_small: 36px !default;
$dropdown_padding: 18px !default;

$gray_dark: #444 !default;
$gray: #999 !default;
$gray_light: #e8e8e8 !default;
$gray_lighter: #f6f6f6 !default;
$primary_light: $gray !default;
$arrow_color: $gray !default;

// Style the dropdown
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: $input_border_radius;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: $font_size;
  font-weight: normal;
  outline: none;
  padding-left: $dropdown_padding;
  padding-right: $dropdown_padding + 12;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  height: 50px;
  line-height: 50px;
  color: #a3a1a1;

  &:hover {
    border-color: darken($gray_light, 5%);
  }
  &:active, &:focus {
    border-color: $primary_light;
  }
  // Arrow
  &:after {
    border-bottom: 1px solid #7A7A7A;
    border-right: 1px solid #7A7A7A;
    content: '';
    display: block;
    height: 9px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 15px;
    top: 48%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 8px;
  }
  
  &.open {
    @extend :active;
    &:after {
      transform: rotate(-135deg);
    }

    &:before {
      content: " ";
      position: absolute;
      right: 10px;
      bottom: -15px;
      border-top: none;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid white;
      z-index: 10;
    }

    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
      width: 100%;
      box-shadow: 0 0 10px 5px rgba(160, 160, 160, 0.15);
      margin-top: 15px;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(180, 180, 180);
        border-radius: 10px;
      }
    }

    &:after {
      border-bottom: 1px solid white;
      border-right: 1px solid white;
    }

    color: white;
    background-color: #07adcb;
  }
  &.disabled {
    border-color: lighten($gray_light, 2%);
    color: $gray;
    pointer-events: none;
    font-style: italic;
    opacity: 0.5;

    &:after { 
      border-color: lighten($arrow_color, 20%);
    }
  }
  
  // Modifiers
  &.wide {
    width: 100%;
    .list {
      left: 0 !important;
      right: 0 !important;
    }
  }
  &.right {
    float: right;
    .list {
      left: auto;
      right: 0;
    }
  }
  &.small {
    font-size: $font_size_small;
    height: $input_height_small;
    line-height: $input_height_small - 2;
    &:after { 
      height: 4px;
      width: 4px;
    }
    .option {
      line-height: $input_height_small - 2;
      min-height: $input_height_small - 2;
    }
  }
  
  // List and options
  .list {
    background-color: #fff;
    border-radius: $input_border_radius;
    box-shadow: 0 0 0 1px rgba($gray_dark, .11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%; left: 0;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(- $input_height / 2);
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9;
    color: #a3a1a1;

    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }
  }
  .option {
    cursor: pointer;
    font-weight: 400;
    line-height: $input_height - 2;
    list-style: none;
    min-height: $input_height - 2;
    outline: none;
    padding-left: $dropdown_padding;
    padding-right: $dropdown_padding + 11;
    text-align: left;
    transition: all 0.2s;
    position: relative;

    &:hover{
      &:before {
        content: " ";
        position: absolute;
        left: 0;
        width: 4px;
        height: 50%;
        background-color: #07adcb;
        top: 27%;
      }
    }
    &.selected { 
      font-weight: bold;
    }
    &.disabled {
      background-color: transparent;
      color: $gray;
      cursor: default;
    }
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .list {
    display: none;
  }
  &.open {
    .list {
      display: block;
    }
  }
}