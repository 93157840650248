.banner-container {
  .header-image {
    width: 100%;
    height: auto;
  }
}


a.cta-cms,
.btn-orange {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border: 1px solid;
  border-radius: 1.2rem;
  text-align: center;
  padding: 1.5rem 2.3rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-style: normal;
  font-family: GalaxiePolarisBook, sans-serif;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease;
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease;
  border-color: $crd-secondary-color;
  color: $crd-primary-color;

  &:hover {
    color: #fff;
    background: $crd-primary-color;
    border-color: $crd-primary-color;
  }
  &.disabled {
    border-color: #7c7a7a;
  }
}

.itop10-cms {
  .display-pi {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 3rem;
    h3 {
      margin-bottom: 0;
    }
  }
  .sidebar {
    position: fixed;
    top: 400px;
    background: #e4edf6;
    font-family: NeoSansProMedium, sans-serif;
    font-weight: 300;
    padding: 2rem;
    @media (max-width: 500px) {
        display: none;
    }
    @media (min-width: 767px) {
      width: 165px;
      margin-left: 0;
      left: 3rem;
    }
    @media (min-width: 1024px) {
      width: 200px;
    }
    @media (min-width: 1200px) {
      margin-left: -530px;
      left: 50%;
    }
    ul {
      margin-bottom: 0;
    }
    li {
      line-height: 3.5rem;
      a {
        font-size: 2.5rem;
        @media (max-width: 1023px) {
          font-size: 2rem;
        }
        color: $crd-primary-color;
        &.active {
          font-weight: 400;
        }
      }
    }
  }
  h3 {
    font-size: 3.5rem;
    color: $crd-primary-color;
    margin-bottom: 30px;
  }

  h4 {
    font-size: 2.5rem;
    color: $crd-primary-color;
    margin: 0
  }

  .schedule {
    .row {
      padding: 2rem 0;
      line-height: 1.5;
    }
    .row:nth-child(2n) {
      background: #c7daeb;
    }
    .row:nth-child(2n+1) {
      background: #e4edf6;
    }
    .row.heading {
      background: none;
      color: $crd-primary-color;
    }
  }
  #custom-text {
    @media (min-width: 1200px) {
      .text-block {
        max-width: 1200px;
        text-align: left;
      }
    }
    @media (min-width: 767px) {
      .text-block {
        text-align: left;
      }
      h2 {
        text-align: left;
      }
    }
  }
}

#custom-text {
  background: white;

  .title-multiline {
    text-align: right;
  }

    .text-block {
      padding: 0 15px;
      margin: 50px auto 60px auto;
      @media (min-width: 1200px) {
        max-width: 80rem;
      }
      text-align: center;
      font-size: 17.5px;
  }

  p {
    font-size: 2rem;
    line-height: 2.8rem;
    color: $crd-primary-color;
    margin-bottom: 30px;
  }

  .left-line {
    margin-top: 0;
    padding: 0 30px;
    position: relative;
    line-height: 30px;
    font-size: 18px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 0;
      height: 100px;
      border-right: 2px solid #00AED8;
    }
  }

  h2 {
    margin: 0 auto 50px auto;
    font-size: 5.2rem;
    color: $crd-primary-color;
    text-align: center;
    font-family: 'NeoSansProMedium', sans-serif;
    font-weight: 300;

    + p {
      margin-bottom: 30px;
    }
  }

  .drop-container h2 {
    font-size: 4rem;
  }

  .image-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    margin: 40px 0;

    .text-space {
      margin-left: 30px;
    }

    h3 {
      margin: 0 0 2rem 0;
      padding: 0;
      text-align: left;
    }

    p {
      margin-bottom: 0;
      text-align: left;
    }

    img {
      max-width: 300px;
    }

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 20px;

      .text-space {
        margin-left: 0;
      }
      img {
        max-width: 100%;
      }
    }

    h3 {
      margin-bottom: 30px;
    }

    p {
      color: #fff;
    }
  }
}


@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
}

@media (max-width: 767px) {
  #custom-text {
    .title-multiline {
      text-align: left;

      &:after {
        content: '';
        position: absolute;
        left: 30px;
        bottom: 0;
        border-bottom: 2px solid #00aed8;
        width: 100px;
        height: 0;
      }
    }

    .left-line {
      padding-top: 15px !important;

      &:before {
        display: none;
      }
    }
  }
}

.full-bg-grey {
  background-color: rgb(244, 246, 252);
  padding: 50px 0;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

#custom-text .full-bg-blue h3 {
  color: #fff;
  font-size: 4rem;
}

#custom-text .full-bg-grey h3,
#custom-text .full-bg-grey p {
  color: $crd-primary-color;
}

.full-bg-blue {
  background-color: #003b5c;
  padding: 50px 0;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  color: #fff;
}

//.itop-concept {
.category-box-container {
  padding: 0;

  &:nth-child(odd) > div {
    background: #003b5c;
  }

  &:nth-child(even) > div {
    background: #00aed8;
  }
}

.box-row {
  padding-right: 5px;
  padding-left: 5px;
}


.category-box {
  margin: 0 5px;
  padding: 27px 0 23px 0;
  font-size: 1vw;
  position: relative;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.category-box {
  cursor: pointer;
}

.category-box img {
  margin-bottom: 5px;
}

.box-text {
  text-align: center;
}

.drop-container {
  display: none;
  margin: 40px 0 0 0;
}

.drop-container .btn {
  background-color: #003B5C;
  transition: all .25s ease;
}

.drop-container .btn:hover {
  background-color: #00aed8;
}

@media (max-width: 1200px) {
  .category-box {
    font-size: 0.8em;
  }
}

@media (max-width: 920px) {
  .category-box {
    padding: 30px 15px;
  }
  .category-box img {
    display: none;
  }
  .box-row {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 420px) {
  .box-row {
    flex-direction: row;
  }
  .category-box-container {
    max-width: none;
    flex: 1 1 100%;
    padding: 0 10px;
  }
  .category-box {
    padding: 15px 0;
  }
  .drop-container .title-multiline,
  .drop-container .left-line {
    text-align: center;
  }
  #custom-text .drop-container h2 {
    margin-bottom: 2rem;

    &:after {
      left: 50%;
      margin-left: -50px;
    }
  }
  #custom-text .drop-container .title-multiline {
    &:after {
      left: 50%;
      margin-left: -50px;
    }
  }
}

//}