.contact-rich {
  color: $gray;
  margin-bottom: 2rem;
  word-wrap: break-word;

  h4 {
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: 2rem;
  }

  .block {
    height: auto;
    overflow: visible;

    .icon {
      float: left;
      width: 3.5rem;

      i {
        font-size: 2rem;
      }
    }

    .data {
      color: $gray-darker;
      font-size: 14px;
      width: auto;
      overflow: hidden;

      &.email {
        padding-top: 0.375rem;
      }
    }

    a {
      font-size: 14px;
    }

    a[href^="mailto:"] {
      clear: both;
      display: block;
      margin-top: 1rem;

    }
  }
}

.contact-form {
  background: white;
  padding: 1rem;
  color: $gray;
  width: 100%;

  h3 {
    text-transform: uppercase;
    color: $gray-darker;
  }
}

#contact {
  #wrapper {
    background: #f2f2f2;
    padding-bottom: 75px;
  }

  .title {
    text-align: center;
    font-weight: normal;
    margin: 30px auto;
    padding: 15px;
    position: relative;
    font-size: 26px;
    letter-spacing: 1.3px;
    line-height: 1.85;

    &:after {
      content: '';
      border-bottom: solid 2px #07adcb;
      width: 10%;
      position: absolute;
      bottom: 0;
      left: 45%;
    }
  }

  .subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.8px;
    position: relative;
    padding: 20px 0;
    margin-bottom: 40px;

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: solid 1px #cccccc;
    }
  }

  label {
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.7px;
    color: #333333;
  }

  input[type=text]:not(#ui-au-search-input),
  input[type=email],
  input[type=number],
  select,
  textarea {
    height: 45px;
    border-radius: 5px;
    border-width: 0.5px;
    border-color: #ffffff;
    color: #777777;
    padding: 10px;
    width: 100%;

    &:focus {
      box-shadow: none;
      border: solid 0.5px #04adcb;
      border-radius: 5px;
      outline: none;
    }
  }

  textarea {
    height: initial;
  }

  .card {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .orange-button {
    margin-top: 50px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    background: #00AED8;
    color: #ffffff;
    text-align: center;
    padding: 15px 0;
    border: none;
    border-radius: 30px;

    &:focus {
      outline: none;
    }

    &:hover {
      background: #07adcb;
    }

    &:active {
      background: #0062cc;
    }
  }

  .form-container {
    padding: 0;
  }

  .breadcrumb {
    display: none;
  }

  .main-form .row div div p a {
    color: #07adcb;
  }
}

#contact-recaptcha{
  float: right;
}
