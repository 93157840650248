#lecturer-listing.collabs {
  .centered-title {
    text-align: center;
    color: #19547e;
    font-size: 30px;
    text-decoration: underline;
    margin-bottom: 25px;
  }

  hr {
    margin: 50px auto;
    max-width: 100px;
    border-top-width: 2px;
    border-top-color: #003b5c;
  }

  .member .lecturer-picture-container {
    background-color: white;
  }
}

#lecturer-listing {
  body {
    background: white;
    text-align: left;
  }

  #scrollToTop {
    display: none;
    position: fixed;
    bottom: 290px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #00AED8;
    color: #ffffff;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;

    &:hover {
      background-color: #07adcb;
    }
  }

  .title-container {
    text-align: center;
    width: 100%;
    margin: 25px auto;

    h2 {
      font-weight: normal;
      font-size: 2.6rem;
      position: relative;
      padding-bottom: 2rem;

      &:after {
        content: "";
        width: 97px;
        border-bottom: 1px solid #04adcb;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
  }

  .local-manager-container {
    background-color: #f2f2f2;
    padding: 30px;

    .table-head {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .info-container {
      background: #ffffff;
      width: 100%;
      margin: 7px auto;
      border-left: solid 5px #19547e;
      padding: 20px 0 20px 10px;

      div {
        max-width: 100%;
        word-break: break-word;
      }

      .manager-name {
        text-transform: uppercase;
      }

      .email-name {
        padding-left: 18px;

        a {
          color: #07adcb;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .institution {
        padding-left: 21px;
      }

      .country {
        padding-left: 25px;
      }
    }

  }

  .filter-container {
    background: linear-gradient(180deg, #88cbe1 50%, #8ac2e8 75%, #8ab3ed 100%);
    border-radius: 9px;
    padding: 4% 3% 5% 3%;
    position: relative;
    margin-bottom: 75px;

    label {
      color: white;
    }
  }

  #no-result-error {
    display: none;
    text-align: center;
    font-size: 15px;
    margin: 50px auto;
    font-weight: normal;
  }

  #type-select {
    width: 100%;
    border-radius: 5px;
    font-size: 1.4rem;

    &:focus {
      outline: none;
    }

  }

  .member h3 {
    font-weight: normal;
    padding: 15px 0;
    margin-bottom: 0;
  }

  .member {
    padding-bottom: 25px;

    .lecturer-picture-container {
      cursor: pointer;
      //background-color: #DCDCDC;
      border-top-left-radius: 28px;
      border-top-right-radius: 28px;

      .lecturer-portrait {
        width: 100%;
        border-top-left-radius: 28px;
        border-top-right-radius: 28px;
        height: auto;
      }
    }

    .bellow-picture {
      cursor: pointer;
      background-color: $crd-primary-color;
      color: white;
      min-height: 200px;
      text-align: center;
      position: relative;
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
      padding: 2rem 1.5rem;

      &:hover .learn-more {
        color: lighten(#43d6f0, 15%);
      }

      h2 {
        padding: 15px 0;
        color: #fff;
        font-size: 2.4rem;
        line-height: 3rem;
        font-family: NeoSansProMedium, sans-serif;
        font-weight: 300;
      }

      h4 {
        font-weight: 300;
        font-size: 14px;
        color: #fff;

      }

      .learn-more {
        font-weight: bold;
        position: absolute;
        bottom: 30px;
        color: #43d6f0;
        cursor: pointer;
        text-align: center;
        width: 90%;

        &:after {
          padding-left: 5px;
          content: "\f061";
          font-weight: 300;
          font-family: FontAwesome;
          transition: 0.15s padding ease-out;
        }
        &:hover {
          &:after {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .modal-content {
    border-radius: 5px;
    box-shadow: 0 0 9px 1px rgba(188, 188, 188, 0.28);
    margin: 10% auto;
    width: 40%;

    p, li {
      word-break: break-word;
    }

    .basic-info {
      padding: 35px 70px;

      .detail-name {
        font-size: 3rem;
        font-weight: 300;
        font-family: NeoSansProMedium, sans-serif;
      }

      .detail-title {
        color: #777777;
        font-weight: normal;
      }
    }

    .additional-info {
      background: #19547e;
      color: white;
      padding: 35px 70px;
      line-height: 1.5em;

      h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
        margin: 1.5rem 0;
      }

      p, li {
        color: white;
        padding-bottom: 1.5rem;
      }

      a {
        color: #fff;
        &:hover {
          color: #43d6f0;
        }
      }

      td {
        color: white;
        padding: 5px;
        border: solid 1px white;
      }
      h3 {
        color: #fff;
      }
    }

    .close {
      background: $crd-primary-color;
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 1px 9px 9px 9px;
      font-weight: normal;
      opacity: 1;
      color: white;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .cms-box img {
    max-width: 100%;
  }

  .header-image {
    width: 100%;
    height: auto;
  }

  .header-text-container {
    position: absolute;
    top: 30%;
    left: 40%;
    color: #ffffff;
  }

  .header-text-container div {
    display: inline;
    position: relative !important;
  }

  .header-text-container .header-title {
    display: inline;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1.8px;
    position: relative;
    height: 100%;
    padding: 31px 10px;
    border-right: solid 1px #ffffff;
  }

  .header-text-container .description-box {
    float: right;
    max-width: 320px;
    width: 320px;
    padding: 20px 10px;
  }

  .header-description {
    color: #ffffff;
    position: relative;
    display: inline;
    width: 320px;
    max-width: 320px;
    text-align: left;
  }

  .cms-content-container {
    padding: 80px 15%;
  }

  .lecturer-picture-container {
    position: relative;
    cursor: pointer;
    border: 1px solid #dedede;
    padding: 3rem;
    .expand-icon {
      position: absolute;
      bottom: 20px;
      right: 0;

      &:hover {
        filter: none;
      }
    }
  }


  .details-container .row {
    margin: 0;
  }

  .details-container h2 {
    font-weight: normal;
    margin: 8px 0;
  }

  .details-subtitle {
    color: #777777;
    font-weight: normal;
    margin: 8px 0;
  }

  .details-container .details-content, .details-container .details-category {
    padding: 30px 0;
  }

  .details-container .details-category h3 {
    font-weight: normal;
    word-break: break-word;
  }

  .details-content h3 {
    font-weight: 600;
  }

  .details-content a {
    color: #04adcb;
  }

  .triangle-container {
    max-height: 30px;
    overflow: hidden;
  }

  #country-select, label[for=country-select] {
    font-size: 15px;
  }
}

@media (max-width: 1200px) {
  #lecturer-listing {
    .local-manager-container {
      .table-head {
        display: none;
      }

      .info-container {
        .country, .email-name, .institution, .manager-name {
          padding-left: 15px;
          margin: 10px auto;
        }
      }
    }

    .modal-content {
      width: 50%;

      .additional-info {
        padding: 35px 60px;
      }
    }
  }
}

@media (max-width: 767px) {
  #lecturer-listing {
    .modal-content {
      width: 60%;

      .additional-info {
        padding: 35px 45px;
      }
    }
  }
}

@media (max-width: 550px) {
  #lecturer-listing {
    .modal-content {
      width: 75%;
    }

    .additional-info {
      padding: 35px 25px;
    }
  }
}
