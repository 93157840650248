#products,
#pagenotfound {
  #main {
    .page-header {
      margin: 2rem 0 3rem;
    }

    .page-content {
      margin-bottom: 10rem;
    }
  }

  .page-not-found {
    @include box-shadow;
    background: white;
    padding: 1rem;
    font-size: $font-size-sm;
    color: $gray;
    max-width: 570px;
    margin: 0 auto;

    h4 {
      font-size: $base-font-size;
      font-weight: 700;
      margin: 0.5rem 0 1rem;
    }

    .search-widget {
      float: none;

      input {
        width: 100%;
      }
    }
  }
}

.not-found-error-container {
  .container {
    padding: 150px 0;
    text-align:center;
    h1 {
      font-size: 120px;
      font-weight:500;
    }
    h2 {
      font-size: 2.5rem;
      color: #808080;
      margin: 30px auto;
    }
    .not-found-message {
      position:relative;
      padding-bottom: 40px;
      margin-bottom: 80px;
      &:after {
        content: '';
        position:absolute;
        bottom: 0;
        width: 420px;
        border-bottom: 1px solid #D3D3D3;
        left: calc(50% - 210px);
      }
    }
  }
}