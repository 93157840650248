.lock-scroll {
  position: fixed;
}

#mobile_top_menu_wrapper {
  position: fixed;
  left: -5000px;
  width: calc(100% - 45px);
  max-width: 400px;
  margin-top: 0;
  z-index: 10000;
  height: 100vh;
  padding: 0;
  color: $nav-background-color;
  background: $nav-links-color;
  overflow-y: scroll;

  li {
    line-height: 47px;
  }
}

.mm-list > li.my-account {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  a {
    line-height: 30px;
    box-sizing: border-box;
    &.mm-subopen {
      top: 11px;
    }
  }
  .small-title {
    font-size: 12px;
    color: #a0a0a0;
    text-transform: uppercase;
  }
  .user-initals {
    background: $nav-background-color;
    color: $nav-links-color;
    padding: 5px;
    min-width: 32px;
    height: 32px;
    line-height: 28px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
  }
  .register-now {
    font-size: 13px;
    text-align: center;
  }
}
.mm-list:not(.mm-current) > li.my-account {
  z-index: -1;
  display: none;
}

.mm-list:not(.mm-current) > li.support-menu {
  z-index: -1;
  display: none;
}

#mmenu {
  font-family: NeoSansProMedium, sans-serif;
}
#mmenu ul[data-depth="0"].mm-current{
  padding-top: 70px;
  + div.language-selector {
    display: block;
  }
}
#mmenu div.language-selector {
  display: none;
  position: absolute;
  z-index: 0;
  left: 35px;
  top: 25px;
  width: 100px;
  height: auto;
  .fa {
    color: $nav-background-color;
  }
  .dropdown-menu.show {
    border: none;
    padding: 10px 0;
    .dropdown-item {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

#mmenu .lang-flag {
  width: 24px;
  margin-right: 5px;
}

#mmenu a.close-menu {
  content: '';
  background: url('../img/icons/close-menu.svg') no-repeat;
  display: inline-block;
  z-index: 1;
  right: 25px;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 30px;
}

#mmenu a.btn.btn-primary {
  display: inline-block;
  padding: 0 20px;
  height: 34px;
  line-height: 34px !important;
  color: #fff;
  margin: 10px 0 10px 40px;
}

#mmenu li.my-account a.btn.btn-primary {
  height: 50px;
  line-height: 50px !important;
  width: calc(100% - 80px);
  font-size: 14px;
}

.main-menu #mmenu.hidden-desktop ul {
  display: none;
}

.globomenu-sub-indicators .globomenu-has-submenu-drop>.globomenu-target>span:after,
a.globomenu-target.globomenu-has-content>span:after {
  position: absolute;
  background: url('../img/icons/arrow-down-white.svg') no-repeat left center;
  content: '';
  width: 10px;
  height: 4px;
  margin-top: -4px;
  top: 50%;
}
li:not(.globomenu-item-level-0) a.globomenu-target.globomenu-has-content > span:after {
  background: url('../img/icons/arrow-down.svg') no-repeat left center;
  right: 2px;
  transform: rotate(-90deg);
  margin-top: 0;
}

.globomenu {
  .globomenu-submenu {
    p {
      display: none;
    }
  }

  .globomenu-item {
    ul.globomenu-submenu-drop {
      padding-top: 5px;
      padding-bottom: 15px;
    }
  }
}

.mm-list {
  .contact-details {
    &.mm-selected {
      > a,
      > span {
        background: none !important;
      }
    }
    position: relative !important;
    .store-info-title {
      display: inline-block !important;
    }
    a {
      position: relative;
      color: $nav-background-color;
      border: 1px solid $nav-background-color;
      border-radius: 12px;
      display: inline-block;
      padding: 0 !important;
      width: 24px !important;
      height: 24px !important;
      line-height: 24px;
      top: 13px !important;
      margin: 0 30px 0 0 !important;

      &.phone {
        background: #fff url('../img/icons/phone-icon-small.svg') no-repeat 6px center;
        right: 30px;
      }

      &.email {
        background: #fff url('../img/icons/email-icon-small.svg') no-repeat 6px center !important;
        left: 0px;
        right: auto;
        float: right;
      }

      span.value {
        width: 0;
        display: none;
        padding-left: 22px;
        padding-right: 9px;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
        line-height: 21px;
      }

      &.show {
        width: auto;
        float: none;
        margin: 2px 0 20px;

        span.value {
          width: auto;
          display: inline-block;
        }
      }
    }
  }
  .chat {
    text-transform: uppercase;
    .livechat_button {
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCAxMkMyNCAxNy43OTkgMTguNjI3IDIyLjUgMTIgMjIuNUMxMC44MTE1IDIyLjUwMTYgOS42Mjc4OCAyMi4zNDczIDguNDc5NSAyMi4wNDFDNy42MDM1IDIyLjQ4NSA1LjU5MiAyMy4zMzcgMi4yMDggMjMuODkyQzEuOTA4IDIzLjk0IDEuNjggMjMuNjI4IDEuNzk4NSAyMy4zNDlDMi4zMjk1IDIyLjA5NSAyLjgwOTUgMjAuNDI0IDIuOTUzNSAxOC45QzEuMTE2IDE3LjA1NSAwIDE0LjY0IDAgMTJDMCA2LjIwMSA1LjM3MyAxLjUgMTIgMS41QzE4LjYyNyAxLjUgMjQgNi4yMDEgMjQgMTJaTTcuNSAxMkM3LjUgMTIuMzk3OCA3LjM0MTk2IDEyLjc3OTQgNy4wNjA2NiAxMy4wNjA3QzYuNzc5MzYgMTMuMzQyIDYuMzk3ODIgMTMuNSA2IDEzLjVDNS42MDIxOCAxMy41IDUuMjIwNjQgMTMuMzQyIDQuOTM5MzQgMTMuMDYwN0M0LjY1ODA0IDEyLjc3OTQgNC41IDEyLjM5NzggNC41IDEyQzQuNSAxMS42MDIyIDQuNjU4MDQgMTEuMjIwNiA0LjkzOTM0IDEwLjkzOTNDNS4yMjA2NCAxMC42NTggNS42MDIxOCAxMC41IDYgMTAuNUM2LjM5NzgyIDEwLjUgNi43NzkzNiAxMC42NTggNy4wNjA2NiAxMC45MzkzQzcuMzQxOTYgMTEuMjIwNiA3LjUgMTEuNjAyMiA3LjUgMTJaTTEzLjUgMTJDMTMuNSAxMi4zOTc4IDEzLjM0MiAxMi43Nzk0IDEzLjA2MDcgMTMuMDYwN0MxMi43Nzk0IDEzLjM0MiAxMi4zOTc4IDEzLjUgMTIgMTMuNUMxMS42MDIyIDEzLjUgMTEuMjIwNiAxMy4zNDIgMTAuOTM5MyAxMy4wNjA3QzEwLjY1OCAxMi43Nzk0IDEwLjUgMTIuMzk3OCAxMC41IDEyQzEwLjUgMTEuNjAyMiAxMC42NTggMTEuMjIwNiAxMC45MzkzIDEwLjkzOTNDMTEuMjIwNiAxMC42NTggMTEuNjAyMiAxMC41IDEyIDEwLjVDMTIuMzk3OCAxMC41IDEyLjc3OTQgMTAuNjU4IDEzLjA2MDcgMTAuOTM5M0MxMy4zNDIgMTEuMjIwNiAxMy41IDExLjYwMjIgMTMuNSAxMlpNMTggMTMuNUMxOC4zOTc4IDEzLjUgMTguNzc5NCAxMy4zNDIgMTkuMDYwNyAxMy4wNjA3QzE5LjM0MiAxMi43Nzk0IDE5LjUgMTIuMzk3OCAxOS41IDEyQzE5LjUgMTEuNjAyMiAxOS4zNDIgMTEuMjIwNiAxOS4wNjA3IDEwLjkzOTNDMTguNzc5NCAxMC42NTggMTguMzk3OCAxMC41IDE4IDEwLjVDMTcuNjAyMiAxMC41IDE3LjIyMDYgMTAuNjU4IDE2LjkzOTMgMTAuOTM5M0MxNi42NTggMTEuMjIwNiAxNi41IDExLjYwMjIgMTYuNSAxMkMxNi41IDEyLjM5NzggMTYuNjU4IDEyLjc3OTQgMTYuOTM5MyAxMy4wNjA3QzE3LjIyMDYgMTMuMzQyIDE3LjYwMjIgMTMuNSAxOCAxMy41WiIgZmlsbD0iIzJBNTI3RSIvPgo8L3N2Zz4K) no-repeat right 30px center;
    }
    a.chat-bubble {
      font-size: 15px;
      color: #185381;
    }
  }
}

.support-menu {
  position: absolute !important;
  top: 8px !important;
  border: 0 !important;
  > span {
    text-transform: none !important;
  }
  a.mm-subopen {
    width: 100% !important;
    &:after {
      display: none !important;
    }
  }
  .support i {
    background: url('../img/icons/support-icon-blue.svg') no-repeat;
    display: inline-block;
    height: 32px;
    padding-left: 35px;
    font-style: normal;
    line-height: 32px;
  }
}


#mmenu {
  .support-menu {
    right: 30px;
    margin-top: -2px;
  }
}
