#training {
  .pdf-container {
    text-align: center;
    margin-top: 50px;

  }

  .jumbotron {
    padding: 0;
    background: white;
    overflow: hidden;
    max-height: 500px;

    img {
      position: relative;
      height: auto;
      width: 100%;
    }
  }

  .course-head {
    background: white;
    position: relative;
    top: -66px;
    box-shadow: 0 0 7px 0 rgba(51, 122, 183, 0.14);
    color: white;
    border-radius: 4px;

    .container {
      position: relative;
    }

    .course-date {
      padding: 3rem 3rem;
      text-align: center;

      span {
        display: block;
      }
    }

    #course-day {
      font-size: 2.4rem;
      color: #00AED8;
      padding-bottom: 15px;
      line-height: 2.3rem;
    }

    #course-month {
      font-size: 1.6rem;
      color: $dark-gray;
      text-transform: uppercase;
      padding-bottom: 13px;
    }

    #course-year {
      color: $light-gray;
      font-size: 1.1rem;
    }

    .course-info {
      padding: 25px 20px;
    }

    .course-type {
      font-size: 1.6rem;
      font-weight: normal;
      color: $btn-primary-bg;
    }

    .course-name {
      font-size: 2.8rem;
      color: $highlight-color;
      font-weight: normal;
      margin: 0.6rem 0 1rem;
    }

    .course-time {
      font-size: 1.4rem;
    }

    .course-hours {
      color: $light-gray;
      float: left;
    }

    .course-duration {
      color: #00AED8;
      position: relative;
      padding: 0 15px;
      margin-left: 11px;

      &:before {
        content: "";
        border: 1px solid #00AED8;
        position: absolute;
        left: 0;
        height: 16px;
        top: 4px;
      }
    }

    .ce-certificate {
      color: #04adcb;
      margin: 10px 0 0 0;
      font-weight: bold;
    }

    .booking-full {
      position: absolute;
      display: block;
      top: 0;
      left: 30vw;
      color: #ED1C24;
      padding: 0 10px;
      font-size: xx-large;
      font-weight: 900;
      transform: rotate(-15deg);
      -webkit-transform: rotate(-15deg);
      text-transform: uppercase;
    }

    .date-bck {
      background: #e1f5f9;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  .back-link {
    padding: 20px 0;
  }

  .btn-trgray {
    color: #AAA;
    background: #FFF;
    border: 2px solid #AAA;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    line-height: 36px;
    padding: 0 15px;
    min-width: 172px;
  }

  .text-block {

    .left-line {
      margin-top: 0;
      padding: 0 30px;
      position: relative;
      line-height: 30px;
      font-size: 18px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        height: 100px;
        border-right: 1.5px solid $btn-primary-bg;
      }
    }

    .intro-title {
      font-size: 2.4rem;
      color: $dark-gray;
      font-weight: normal;
      text-align: right;
      line-height: 3.6rem;
      margin-right: 2rem;
    }

  }

  .course-intro {
    margin-bottom: 10rem;

    p {
      color: $light-gray;
      font-size: 1.4rem;
      margin-bottom: 2.6rem;
      line-height: 2.5rem;
    }
    ol {
      margin-bottom: 2.5rem;
      padding-left: 1.5rem;
      li {
        font-size: 1.4rem;
        color: $dark-gray;
        position: relative;
      }
    }
    ul {
      li {
        font-size: 1.4rem;
        color: $dark-gray;
        margin-bottom: 2.5rem;
        padding-left: 2.5rem;
        position: relative;

        &:before {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          position: absolute;
          left: 0;
          top: 10px;
          color: $highlight-color;
        }
      }
    }
    .your-program ul {
      list-style: none !important;
      li {
        margin-bottom: 0;
      }
    }

    .learning-objectives {
      font-weight: bold;
    }
  }

  .course-details {
    color: $content-color;
    background: $light-bkg;
    margin: 0;
    padding: 50px 0 100px;

    p, #included-benefits {
      line-height: 2.5rem;
      color: $content-color;
    }

    .language-detail {
      color: #00AED8;
    }

    .details-section {
      padding: 20px 30px;
    }

    .section-title {
      font-size: 1.8rem;
      font-weight: normal;
      display: flex;
      vertical-align: center;

      .fa-user {
        font-size: 23px;
        margin-right: 12px;
      }
    }

    .training-details {
      line-height: 25px;
    }

    #training-title {
      margin-bottom: 19px;
    }

    .webinar-link-wrapper {
      margin: 10px 0;
    }

    .map-location {
      margin: 10px 0;
    }

    .sponsored-by {
      margin-top: 1rem;

      .sponsor {
        padding: 0;
        color: #00AED8;
      }
    }

    .middle-section {
      &:after {
        content: "";
        height: 90%;
        border-left: 1px solid $content-border;
        position: absolute;
        top: 13px;
      }

      &:after {
        right: 0;
      }
    }

    .map-marker-icon {
      margin-right: 4px;
    }

    .target-audience {
      color: $highlight-color;
    }

    .recommended-for-orange {
      color: #00AED8;
    }

    .section-participants {
      margin-top: 3rem;
    }

    .tickets-price {
      color: $highlight-color;
      display: block;
      margin-bottom: 13px;

      #value {
        font-weight: bold;
      }
    }

    .event-view-sold-out {
      color: #00AED8;
      margin-bottom: 0;
    }

    .sold-out-summary {
      line-height: 2rem;
    }

    .webinar-video-under {
      max-width: 60%;
      margin: 30px auto 0 auto;
      text-align: center;
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
  .course-details.webinar-recording {
    margin-bottom: 50px;
  }

  .blue-link {
    color: $highlight-color;
  }

  .course-speakers {
    padding: 7rem 0;
  }

  .main-section-title {
    font-size: 2.6rem;
    text-align: center;
    font-weight: normal;
    margin-bottom: 7.5rem;

    &:after {
      display: block;
      position: relative;
      content: '';
      width: 100px;
      height: 0;
      margin: 15px auto;
      border-top: 2px solid $highlight-color;
    }
  }

  #speakers {
    li {
      margin-bottom: 3px;
    }

    .speaker-details {
      height: 270px;
      overflow: hidden;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;

      p {
        margin-bottom: 2px;
      }
    }

    .speaker-name {
      font-size: 3rem;
      margin-bottom: 1.8rem;
      color: $btn-primary-bg;
      line-height: 3rem;
    }

    .speaker-referent {
      color: $highlight-color;
    }

    .speaker-profession {
      color: $light-gray;
      margin: 12px 0;
    }

    .speaker-description {
      line-height: 2.4rem;
     // max-height: 104px;
      overflow: hidden;
      margin-top: 20px;
    }

    .speaker-portrait {
      text-align: center;

      img {
        max-width: 270px;
        width: auto;
        height: 270px;
        margin: 0 auto;
        border-radius: 100%;
        border: 1px solid $btn-primary-bg;
        padding: 2px;
      }
    }

    .profile-link {
      .fa-chevron-right {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        color: #cccccc;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: white;
        position: relative;
        top: 5px;
        margin-left: 10px;

        &:before {
          top: 5px;
          left: 8px;
          position: absolute;
        }
      }

      a:hover {
        color: $btn-primary-bg;

        .fa-chevron-right {
          background: $btn-primary-bg;
          color: white;
        }
      }
    }
  }

  .course-program {
    background: url('../img/program-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    margin: 0;
    padding: 4.5rem 0 10rem;

    .main-section-title {
      margin-bottom: 2.2rem;
    }

    .content {
      color: $light-gray;

      ul {
        margin-bottom: 5rem;

        li {
          margin-bottom: 2rem;

          .fas {
            color: $highlight-color;
            margin-right: 1.5rem;
          }
        }
      }
    }
  }

  .subtitle {
    text-align: center;
    color: $dark-gray;
    margin-bottom: 5.5rem;
  }

  #download-schedule {
    margin-left: 1.7rem;
  }

  .btn-hollow {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  .btn-hollow.btn-orange {
    color: #fff;
    border: 1px solid #00AED8;

    &:hover {
      color: #fff;
      border: 1px solid #04adcb;
    }
  }

  #subtitle-container {
    margin-bottom: 100px;
  }
}

.tooth-icon {
  background-image: url('../img/tooth.png');
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 23px;
  height: 23px;
  margin-right: 12px;
}

.map-marker-icon-o {
  background-image: url('../img/location.png');
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 21px;
  margin-right: 12px;
}

.user-group-icon {
  background-image: url('../img/user-group.png');
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 30px;
  height: 28px;
  margin-right: 12px;
  background-position: 0 5px;
}

.ticket-icon {
  background-image: url('../img/ticket.png');
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 23px;
  height: 23px;
  margin-right: 12px;
}

.download-icon {
  background-image: url('../img/download.png');
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  margin-left: 12px;
}

.webinar-recording {
  margin-bottom: 40px;

  .btn-orange {
    width: auto;
    max-width: 100%;
    padding: 7px 10px 10px 10px;
  }

  video {
    max-width: 100%;
  }

  .event-page-register {
    width: 700px;
    margin: auto;
    margin-top: 50px;
    display: none;

    &:before, &:after {
      display: block;
      position: relative;
      content: '';
      width: 100px;
      height: 0;
      margin: 15px auto;
      border-top: 2px solid #04adcb;
    }

    h3 {
      margin-bottom: 30px;
      font-weight: normal;
      text-align: center;
    }

    .row {
      margin-left: 0;
      margin-right: 0;
      flex-direction: column;
      align-content: center;

      .form-group {
        flex: 45%;
        width: 50%;

        select {
          height: 45px;
          border-radius: 5px;
          border-width: .5px;
          border-color: #fff;
          color: #777;
          padding: 10px;
        }
      }

      #register-participant {
        width: 100%;

        &:hover {
          color: #ffffff;
        }
      }
    }

    .spacer {
      flex: 10%;
    }
  }
}

#webinar-time {
  color: $btn-primary-bg;
}

#vimeoOverlay {
  background: rgba(5,5,5, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
}

#vimeoOverlay p {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  text-align: center;
  width: 100%;
}

@media (min-width: 1200px) {
  #training {
    .jumbotron {
      img {
        top: -50%;
      }
    }
  }
}

@media (max-width: 767px) {
  #training {
    .course-head {
      top: -20px;

      .course-date {
        padding: 1rem 1rem;
      }

      #course-day {
        padding-bottom: 10px;
      }

      #course-month {
        padding-bottom: 8px;
      }
    }
  }
  .course-details {
    text-align: left;

    p {
      margin-bottom: 0;
    }

    .section-title {
      margin: 10px 0;
      display: block !important;
    }

    .details-section {
      margin: 0 50px;

      &:after {
        border-left: none !important;
        border-bottom: 1px solid #e3e3e3;
        left: 0;
        bottom: 0;
        height: 0 !important;
        top: auto !important;
      }
    }
  }
  .main-section-title {
    margin-bottom: 0 !important;
  }
  #training .speaker-details {
    height: auto !important;
    line-height: 2em;
  }
}

@media (max-width: 575px) {
  .course-head {
    .course-info {
      text-align: center;
    }

    .align-items-center {
      padding-bottom: 20px;

      #sign-up {
        margin: 0 auto;
      }
    }
  }
  .course-intro {
    .title-multiline {
      padding: 0 !important;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        height: 0;
        width: 100px;
        border-bottom: 1.5px solid #00AED8;
      }


      .intro-title {
        text-align: left !important;
        margin: 0 0 15px 0;
      }
    }

    .left-line:before {
      border-right: none !important;
    }

  }
}
