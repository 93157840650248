.block_newsletter {
  @include search-box;
  text-align: center;

  h2, h3.title {
    color: #fff;
    margin-bottom: 3rem;
    font-size: 3.5rem;
  }

  p#newsletter-text {
    margin: 0 auto 3rem auto;
    text-align: center;
    color: #fff;
    width: 70%;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  form {
    .input-wrapper {
      overflow: hidden;
    }

    input[type=email] {
      margin-right: 10px;
      max-width: 500px;
      padding: 1.1rem 0;
      border: 0;
      border-bottom: 1px solid #fff;
      background: none;
      outline: 0;
      color: #fff;
      &:focus {
        border: 0;
        outline: 0;
        border-bottom: 1px solid $crd-secondary-color;
      }
      border-radius: 0;
      width: 350px;
      @media (max-width: 768px) {
        width: 50%;
      }
    }

    input {
      height: 4.8rem;
      box-shadow: none;
    }

    button[type=submit] {
      position: static;
      color: #fff;
      border: 1px solid #fff;
      padding: 1.5rem 2.3rem;
      font-size: 1.4rem;
      line-height: 2.3rem;
      &:hover {
        background: $crd-secondary-color;
        color: #fff;
      }
    }
  }

  .input-group {
    margin: 0 auto;
    height: 4.8rem;

    input {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 4.8rem;

      &::placeholder {
        color: $link-color;
      }
    }
  }

  #button-addon {
    width: 16.6rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .alert {
    text-align: center;
    border-radius: 6px;
    font-size: 20px;
    max-width: initial;
    margin: 20px 0;
    display: inline-block;
    padding: 20px;

    &.alert-success {
      color: $alert-success-no-bkg;
    }
  }
}

.block-contact {
  color: $gray;

  .block-contact-title {
    color: $gray-darker;
  }
}

.linklist {
  .blockcms-title a {
    color: $gray-darker;
  }
}

.account-list a {
  color: $gray;

  &:hover {
    color: $brand-primary;
  }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-weight: 700;
  font-size: $base-font-size;
}

.block-social {
  text-align: right;

  ul {
    @include media-breakpoint-down(sm) {
      flex-flow: wrap;
    }

    li {
      height: 2.5rem;
      width: 2.5rem;
      background-color: $gray-light;
      background-repeat: no-repeat;
      display: inline-block;
      margin: 0.125rem;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        height: 2rem;
        width: 2rem;
        margin-right: 1.3rem;
        background-size: contain;
      }

      &:hover {
        background-color: $brand-primary;
      }

      a {
        display: block;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
          color: transparent;
        }
      }
    }
  }
}

.facebook {
  background-image: url(../img/facebook.svg);

  &::before {
    content: "";
    background-image: url(../img/facebook-blue.svg);
  }

  &.icon-gray {
    background-image: url(../img/facebook-gray.svg);

    &:hover {
      background-image: url(../img/facebook-blue.svg);
    }
  }
}

.twitter {
  background-image: url(../img/twitter.svg);

  &::before {
    content: "";
    background-image: url(../img/twitter-blue.svg);
  }

  &.icon-gray {
    background-image: url(../img/twitter-gray.svg);

    &:hover {
      background-image: url(../img/twitter-blue.svg);
    }
  }
}

.rss {
  background-image: url(../img/rss.svg);
}

.youtube {
  background-image: url(../img/youtube.svg);
}

.googleplus {
  background-image: url(../img/gplus.svg);

  &::before {
    content: "";
    background-image: url(../img/gplus-blue.svg);
  }

  &.icon-gray {
    background-image: url(../img/gplus-gray.svg);

    &:hover {
      background-image: url(../img/gplus-blue.svg);
    }
  }
}

#block_myaccount_infos {
  .myaccount-title a {
    color: $gray-darker;
  }
}

.pinterest {
  background-image: url(../img/pinterest.svg);

  &::before {
    content: "";
    background-image: url(../img/pinterest-blue.svg);
  }

  &.icon-gray {
    background-image: url(../img/pinterest-gray.svg);

    &:hover {
      background-image: url(../img/pinterest-blue.svg);
    }
  }
}

.vimeo {
  background-image: url(../img/vimeo.svg);
}

.instagram {
  background-image: url(../img/instagram.svg);
}

.footer-container {
  overflow: hidden;

  .logo-footer {
    padding: 0 20px;
    text-align: center;
  }

  li {
    margin-bottom: 0.3125rem;
  }

  li a {
    cursor: pointer;
    font-size: $font-size-default;
    color: white;

    &:hover {
      color: $brand-primary;
    }
  }
}

.links {
  min-height: 12rem;
  .collapse {
    display: flex;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 3rem;

    li {
      width: 50%;
      display: block;
      margin-bottom: 2rem;
    }
  }

  .d-md-none {
    display: none;
  }
}

.sponsor {
  padding: 5rem 0;
  max-width: 1140px;
  margin: 0 auto;
  .display-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      gap: 0px;
      .sponsor-container {
        width: 30%;
        margin-top: 20px;
        img{
          max-width: 150px;
        }
      }
    }
    @media (max-width: 500px) {
      .sponsor-container {
        width: 50%;
        img{
          max-width: 150px;
        }
      }
    }
    .sponsor-container img{
      max-width: 150px;
    }
  }
  a {
    img {
      max-height: 10rem;
      max-width: 22rem;
      padding: 0;
    }
  }
}

footer .copy {
  padding: 20px 0;
  text-align: center;
  color: #666;
  background: #000;
}

@media only screen and (min-width: 1024px) {
  .block_newsletter .input-group {
    width: 52rem;
  }
}

@include media-breakpoint-down(sm) {
  .footer-container {
    box-shadow: none;
    margin-top: 0;

    .logo-footer {
      margin: 0 auto;
      padding-bottom: 25px;
    }

    .wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .links {
      //margin: 0 3rem;
      //border-top: 1px solid $gray-lighter;

      ul {
        padding-top: 25px;
        margin-left: 0;

        li {
          width: 100%;
        }
      }
    }
  }
  .links {
    .collapse {
      display: none;

      &.in {
        display: block;
      }
    }

    .title {
      padding: 0.625rem;
      //border-bottom: 1px solid $gray-lighter;
      cursor: pointer;

      .collapse-icons .remove {
        display: none;
      }
    }

    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }
  .block-contact {
    padding-left: 1.5rem;
  }
}


