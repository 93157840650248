.renew-password {
  margin-left: 10px;

  .email {
    padding-bottom: 30px;
  }

  footer {
    margin: 34px 0 0;

    .btn {
      width: 100%;
      height: 48px;
      border-radius: 25px;
      margin-bottom: 20px;
    }

    .account-link {
      .material-icons {
        font-size: 27px;
        line-height: 14px;
        height: auto;
        width: auto;
      }
    }
  }
}
