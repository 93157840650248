.carousel {
  margin-bottom: 1.5rem;
  .direction {
    z-index: auto;
  }
  .carousel-inner {
  }
  .carousel-item {
    height: 100%;
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
    .caption {
      position: absolute;
      bottom: 28px;
      left: 90px;
      color: white;
      max-width: 340px;
      .caption-description p {
        color: white;
      }
    }

    .slider-logo {
      max-height: 300px;
      max-width: 300px;
      top: 18%;
      position: absolute;
      left: 6%;
    }

    figure {
      margin: 0;

      .btn-slider {
        position: absolute;
        bottom: 16%;
        left: 42%;
        font-size: 22px;
        min-width: 300px;
        text-transform: uppercase;
        padding: 28px;
        border-radius: 35px;
        color: white;
        background: #00AED8;
        display: inline-block;
        border: none;
      }
    }
  }
  .carousel-control {
    opacity: 1;
    .icon-next,
    .icon-prev {
      &::before {
        content: "";
      }
      i {
        font-size: 3.125rem;
        color: white;
      }
      &:hover {
        i {
          color: $brand-primary;
        }
      }
    }
    .icon-prev {
      left: 1rem;
    }
    .icon-next {
      right: 2rem;
    }
    &.left,
    &.right {
      background: none;
    }
  }
}

@include media-breakpoint-down(lg) {
  .carousel {
    .carousel-inner {
      height: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel {
    .carousel-item {
      .caption {
        bottom: calc(50% - 2rem);
        left: 10%;
      }
      .caption {
        .display-1 {
          font-size: 2rem;
        }
      }
      .caption-description {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .carousel {
    .carousel-item {
      .caption {
        bottom: calc(50% - 1.5rem);
      }
      .caption {
        .display-1 {
          font-size: 1.5rem;
        }
      }
    }
    .carousel-control {
      .icon-prev, .icon-next {
        i {
          font-size: 2rem;
        }
      }
    }
  }
}
