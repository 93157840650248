/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
  @include box-shadow;
  background: white;
  padding: 1rem;
}

@mixin customer-area-box {
  @include customer-area-base-box;
  font-size: $font-size-sm;
  color: $gray;
}

.table-labeled {
  th {
    vertical-align: middle;
  }

  td {
    vertical-align: middle;
  }

  .label {
    font-weight: 400;
    border-radius: 3px;
    font-size: inherit;
    padding: 0.25rem 0.375rem;
    margin: 0.125rem;
    color: white;
    white-space: nowrap;
  }
}

.page-order {
  .table {
    margin-bottom: 0;
  }

  table {
    th {
      padding: 0.5rem;
    }

    td {
      padding: 0.5rem;
    }

    thead {
      th {
        text-align: center;
      }
    }
  }
}

.form-control-submit {
  width: 100%;
  height: 48px;
  border-radius: 25px;
  margin-bottom: 20px;
  margin-top: 9px;
}

#customer-form {
  margin-top: 25px;

  label {
    display: block;
    margin: 3px auto;
  }

  input {
    width: 100%;
  }

  .radio-inline {
    margin-right: 5px;
  }

  .custom-radio {
    width: 14px;
    height: 14px;
  }

  .custom-radio input[type="radio"]:checked + span {
    width: 10px;
    height: 10px;
    left: 0;
    top: 0;
  }

  .custom-checkbox {
    float: left;
    width: initial;

    input[type="checkbox"] + span {
      width: 15px;
      height: 15px;
      border: 2px solid $gray;

      .checkbox-checked {
        font-size: 15px;
        font-weight: bold;
        color: $brand-primary;
      }
    }
  }

  .custom-checkbox-label {
    display: inline-block;
    float: left;
    width: calc(100% - 26px);
    margin: 0 3px;
  }
}

#authentication {
  .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
  }

  .custom-checkbox {
    span {
      flex: 0 0 0.9375rem;
    }

    label {
      padding-left: 0.625rem;
    }
  }
}

#identity,
#authentication {
  .radio-inline {
    padding: 0;
    display: inline;

    .custom-radio {
      margin-right: 0;
    }
  }
}

/*** Most of the customer accpunt pages ***/
.page-customer-account {
  #content {
    .order-actions {
      a {
        padding: 0 0.125rem;
      }
    }

    .forgot-password {
      text-align: center;
      font-size: $font-size-sm;
      margin-top: 1rem;
      padding-bottom: 0.9375rem;
    }

    .no-account {
      text-align: center;
      font-size: $base-font-size;
    }
  }
}

/*** Login page ***/
.page-authentication {
  #content {
    @include customer-area-base-box;
    max-width: 640px;
    margin: 0 auto;
  }
}

.logo-wrapper {
  margin: 0 0 0 75px;
  height: 80px;
  padding-top: 25px;
}

#authentication {
  background: url(../img/authentication-bkg2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  #wrapper {
    background: transparent;
  }

  .email-group {
    margin-bottom: 2.6rem;
  }

  .password-group {
    margin-bottom: 1.8rem;
  }

  .login-form-actions {
    margin-bottom: 65px;

    label {
      margin-bottom: 0;
    }
  }

  .remember-login {
    float: left;

    label {
      color: $highlight-color;
    }
  }

  .forgot-password {
    float: right;
  }

  #submit-login {
    width: 100%;
    height: 48px;
    border-radius: 25px;
    margin-bottom: 20px;
  }

  .small-text {
    color: $light-gray;
  }

  .registration-link {
    color: $btn-primary-bg;
    display: inline-block;
    margin-left: 15px;
    text-decoration: underline;
  }

  .forgot-password {
    font-style: italic;
  }

  form footer {
    margin-bottom: 35px;
  }
}

#password {
  #content-wrapper {
    min-height: 575px;
  }

  background: url(../img/authentication-bkg2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  #wrapper {
    background: transparent;
  }

  .send-renew-password-link {
    margin-bottom: 30px;
  }

  .forgot-control-label {
    margin-top: 6px;
  }

  .buttons-container {
    margin-top: 10px;
    padding: 0;

    div div {
      padding: 30px 20%;
    }
  }

}

#registration {
  #wrapper {
    background: url(../img/registration-bkg.png);
    background-size: cover;
  }
}

#confirmation {
  margin: 50px 0;

  .title3 {
    margin-bottom: 31px;
  }
}

.authentication-container {
  background: white;
  margin: 100px 0;
  padding: 45px 8%;
  border-radius: 6px;
  box-shadow: 0 0 9px 1px rgba(188, 188, 188, 0.52);

  h1 {
    text-align: center;
    margin-bottom: 62px;

    span {
      font-size: 1.8rem;
      color: $dark-gray;
      border-bottom: 1px solid $brand-primary;
      padding-bottom: 15px;
      display: inline-block;
    }
  }

  label {
    text-align: left;
    font-size: 1.4rem;
    color: $light-gray;
    margin-bottom: 10px;
  }

  .form-control {
    height: 48px;
  }

  .styled-checkbox {
    &:hover + label:before {
      background: $highlight-color;
    }

    + label:before {
      position: relative;
      top: 1px;
      border-color: $highlight-color;
    }

    &:checked + label {
      &:before {
        background: $highlight-color;
      }

      &:after {
        top: 11px;
      }
    }
  }
}

.login-container.authentication-container {
  .auth-title {
    margin-bottom: 20px;
  }
  .no-account {
    text-align: center;
    margin-bottom: 40px;
  }
}

/*** Addresses page ***/
.page-addresses {
  .address {
    @include box-shadow;
    background: white;
    margin-bottom: 1.875rem;
    font-size: $font-size-sm;
    color: $gray-darker;

    .address-body {
      padding: 1rem;

      h4 {
        font-size: $base-font-size;
        font-weight: 700;
      }

      address {
        min-height: 9rem;
      }
    }

    .address-footer {
      border-top: 1px solid $gray;
      padding: 0.5rem 1rem;

      a {
        color: $gray;
        margin-right: 0.5rem;

        &:hover {
          color: $gray-darker;
        }

        i {
          font-size: $base-font-size;
        }

        span {
          font-size: $font-size-lg;
          vertical-align: middle;
        }
      }
    }
  }

  .addresses-footer {
    margin: 0 0.9375rem;

    a {
      color: $gray-darker;

      &:hover {
        color: $gray-darker;
      }

      i {
        font-size: $base-font-size;
      }

      span {
        font-size: $base-font-size;
        vertical-align: middle;
        margin-top: $small-space;
      }
    }
  }
}

/*** Order details page ***/
.page-order-detail {
  font-size: 0.875rem;
  color: $gray;

  .box {
    @include customer-area-base-box;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: $base-font-size;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: 1rem;
  }

  #order-infos {
    ul {
      margin: 0;
    }
  }

  #order-history {
    .history-lines {
      .history-line {
        padding: 0.5rem 0;
        border-bottom: 1px solid $gray-lighter;

        &:last-child {
          border-bottom: 0;
        }

        .label {
          display: inline-block;
          margin: 0.25rem 0;
          padding: 0.25rem 0.375rem;
          color: white;
          border-radius: 3px;
        }
      }
    }
  }

  .addresses {
    margin: 0 -0.9375rem;

    h4 {
      font-size: $base-font-size;
      font-weight: 700;
    }
  }

  #order-products {
    &.return {
      margin-bottom: 1rem;

      th {
        &.head-checkbox {
          width: 30px;
        }
      }

      td {
        padding: 1.375rem 0.75rem;

        &.qty {
          min-width: 125px;

          .current {
            width: 30%;
            float: left;
            text-align: right;
            padding-right: 0.5rem;
          }

          .select {
            width: 70%;
            float: left;
            margin: -0.625rem 0;
            padding-left: 0.25rem;

            select {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .order-items {
    padding: 0 !important;

    .order-item {
      padding: 1rem 1rem 0;
      border-bottom: 1px solid $gray-lighter;

      .checkbox {
        width: 30px;
        float: left;
        padding: 0 0.9375rem;
      }

      .content {
        width: calc(100% - 30px);
        float: left;
        padding: 0 0.9375rem;
      }

      .desc {
        .name {
          font-weight: bold;
        }

        margin-bottom: 1rem;
      }

      .qty {
        margin-bottom: 1rem;

        .q {
          margin-bottom: 0.25rem;
        }

        .s {
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  .messages {
    .message {
      margin-top: 0.5rem;
      border-bottom: 1px solid $gray-lighter;

      &:last-child {
        border-bottom: 0;
      }

      > div {
        margin-bottom: 0.5rem;
      }
    }
  }

  .customization {
    margin-top: 0.75rem;
  }
}

/*** Order return page ***/
#order-return-infos {
  .thead-default th {
    color: $gray-darker;
  }

  .customization {
    margin-top: 0.75rem;
  }
}

/*** My account landing page ***/
.page-my-account {
  #main {
    .page-header {
      margin: 25px 0;
      text-align: center;
    }
  }

  #content {
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: auto;
    }
  }
}

#my-events {
  margin-top: 30px;
}

/*** History page ***/
#history {
  .orders {
    margin: 0 -1rem;

    .order {
      a {
        h3 {
          color: $gray;
        }
      }

      padding: 0.75rem 1rem;
      border-bottom: 1px solid $gray-lighter;

      .label {
        display: inline-block;
        margin: 0.25rem 0;
        padding: 0.25rem 0.375rem;
        color: white;
        border-radius: 3px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.contact-submit {
  width: 25%;
}

.group-span-filestyle .btn-default {
  padding: 7.3px !important;
}

/*** FOOTER ***/
.page-footer {
  text-align: right;

  .account-link {
    margin-right: 1rem;

    i {
      font-size: $base-font-size;
    }

    span {
      font-size: $base-font-size;
      vertical-align: middle;
    }
  }
}

.login-form {
  margin-top: 15px;
}

#my-account, #identity, #module-crd_certificates-list, #module-crd_events-panelist {
  #wrapper {
    .form-group {
      margin-bottom: 20px;
    }

    h1, h2, h3, h4 {
      font-weight: normal;
    }

    .page-header h1 {
      text-align: center;
      font-weight: normal;
      margin: 30px auto;
      padding: 15px;
      position: relative;
      font-size: 26px;
      letter-spacing: 1.3px;
      line-height: 1.85;

      &:after {
        content: '';
        border-bottom: solid 2px $brand-primary;
        width: 10%;
        position: absolute;
        bottom: 0;
        left: 45%;
      }
    }

    .account-subtitle {
      margin-bottom: 14px;

      h2 {
        letter-spacing: 1.3px;
        line-height: 1.85;
        padding-bottom: 7px;
      }
    }

    .account-data {
      border-bottom: 1px $gray-light-second ridge;
      border-top: 1px $gray-light-second ridge;
      padding: 15px 0 30px 0;

      .col-6 {
        h3 {
          margin-bottom: 10px;
        }

        h4 {
          color: $gray;
          padding: 15px 10px;
          margin-bottom: 0;
          margin-left: -10px;
        }
      }
      #identity-link {

      }
      #account-form {
        display: none;
        margin: 0;

        div {
          margin-top: 50px;
          font-size: 16px;
          width: 40%;
          text-align: center;
          padding: 15px;
          border: none;
          border-radius: 30px;
          cursor: pointer;
        }
      }
    }

    input, .form-control-select {
      display: block;
      background: $gray-lighter;
      padding: 15px 10px;
      color: $gray;
      border: none;
      height: initial;
      margin: 0;
    }

    label {
      font-size: 16px;
      margin: 0 0 10px;
    }

    .cst-rdio {
      margin: 0;
    }

    .edit-info {
      margin-top: 50px;
      font-size: 16px;
      width: 290px;
      padding: 15px;
      border-radius: 30px;
    }

    .form-control-submit {
      width: 300px;
      text-align: center;
      margin: 70px auto 40px;
      display: block;
    }
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  #authentication {
    .remember-login, .forgot-password {
      float: none;
      margin: 10px 0;
    }

  }
}

@media (max-width: 767px) {
  .login-container {
    margin: 7% 5%;
  }
  .no-account {
    text-align: center !important;
  }
  .psgdprinfo17 {
    text-align: center;

    a {
      margin: 0 !important;
      float: none;
      padding: 8px 9px 10px 9px;
      font-size: 1.3rem;
    }
  }
  .ps-alert-success {
    overflow:hidden;
    word-break: break-word;
  }
}

@media (max-width: 450px) {
  #authentication {
    .remember-login, .forgot-password {
      float: none;
      margin: 10px 0;
    }
  }
  .psgdprinfo17 {
    a {
      font-size: 0.9rem;
      padding: 7px 8px 9px 8px;
    }
  }
  #password .buttons-container div div {
    padding: 30px 15px;
  }
}

@media(min-width: 1199px) {
  #module-crd_participants-confirmation {
    #wrapper {
      overflow: auto;
    }
  }
}