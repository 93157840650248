body,
html {
  min-height: 100%;
  font-size: 10px; /* Now 10px = 1rem! */
  height: 100%;
}

body {
  direction: ltr;
  font-family: $mainFontFamily, sans-serif;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gray-darker;
  line-height: 1.25em;
  position: relative;
  height: auto;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul.top-menu {
  list-style: none;
  padding-left: 0;
}

a:hover {
  color: $brand-primary;
  text-decoration: none;
}

p {
  font-size: 0.9375rem;
  color: $gray;
  font-weight: 400;
}

main {
  min-height: 100%;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
}

.dropdown-menu {
  font-size: 1.4rem;
  color: $link-color;
}

.dropdown-toggle::after {
  content: "";
  border: none;
}

.color,
.custom-checkbox input[type="checkbox"] + span.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  margin: 0.3125rem;
  @include box-shadow-light;
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain;

  &.active,
  &:hover {
    border: 2px solid $gray-darker;
  }
}

.facet-label {
  &.active,
  &:hover {
    .custom-checkbox span.color {
      border: 2px solid $gray-darker;
    }
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  //text-transform: uppercase;
  color: $crd-primary-color;
  font-family: NeoSansProMedium, sans-serif;
  font-weight: 300;
}

h4,
.h4 {
  color: $crd-primary-color;
  font-family: NeoSansProMedium, sans-serif;
  font-weight: 300;
}

.btn {
  font-size: 1.4rem;
  border: none;
}

.btn-primary,
.btn-secondary {
  background: $btn-primary-bg;
  border-radius: $btn-border-radius;
  color: $btn-primary-color;
  line-height: $btn-line-height;
  padding: $btn-padding;

  .material-icons {
    margin-right: $small-space;
  }

  &:hover {
    background: $btn-primary-hover-bg;
  }
}

.btn-tertiary {
  @extend .btn-secondary;
  background-color: #F7F7F7;
  text-transform: lowercase;
  color: $gray;
  box-shadow: 0.0625rem 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.25rem;
  margin: 0.25rem 0;
  font-weight: 400;
  font-size: $font-size-sm;

  .material-icons {
    font-size: $base-font-size;
  }

  &:hover {
    box-shadow: 0.0625rem 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.2);
    border: $input-btn-border-width solid rgba(35, 35, 35, 0.2);
  }
}

button {
  color: $link-color;
}

.btn-unstyle {
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: inherit;

  &:focus {
    outline: 0;

    .expand-more, .fas {
      color: $brand-primary;
    }
  }
}

.card {
  @include box-shadow;
}

label, .label {
  color: $gray-darker;
  font-size: 1.4rem;
}

small.label, small.value {
  font-size: 0.8125rem;
}

.form-control-label {
  padding-top: 0.625rem;
}

.form-control {
  background: $gray-light;
  color: $gray;
  border: $input-btn-border-width solid $input-border-color;
  padding: 0.5rem 1rem;

  &:focus {
    background-color: white;
    outline: 0.1875rem solid $brand-primary;
    color: $gray-darker;
  }
}

input::placeholder {
  font-size: 1.4rem;
  color: $placeholder-color;
}

input:active::-webkit-input-placeholder,
textarea:active::-webkit-input-placeholder,
input:active::-webkit-input-placeholder,
textarea:active::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: $placeholder-color;
}

.input-group {
  &.focus {
    outline: 0.1875rem solid $brand-primary;
  }

  .form-control:focus {
    outline: none;
  }

  .input-group-btn {
    height: 100%;

    > .btn {
      border: 0;
      box-shadow: none;
      color: #ffffff;
      font-size: 0.6875rem;
      font-weight: normal;
      margin-left: 0;
      padding: 0.625rem 1rem;
      text-transform: uppercase;

      &[data-action="show-password"] {
        background: $gray;
        padding: .78rem 1rem;
      }
    }
  }
}

.form-control-select {
  height: 2.625rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: $gray-light url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=") no-repeat scroll right 0.5rem center / 1.25rem 1.25rem;
  padding: 0 0.5rem;
}

.form-control.form-control-select.about-us-select {
  padding: 0 2.6rem;
}

.form-control-valign {
  padding-top: 0.5rem;
}

.form-control-comment {
  font-size: 0.875rem;
  padding-top: 0.5rem;
  color: $gray;
}

.form-control-submit {
  &.disabled {
    background: $brand-info;
    color: white;
  }
}

.form-group {
  &.has-error {
    input,
    select {
      outline: 0.1875rem solid $brand-danger;
    }

    .help-block {
      color: $brand-danger;
    }
  }
}

.group-span-filestyle {
  label {
    margin: 0;
  }

  .btn-default {
    background: $brand-primary;
    color: white;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
}

.bootstrap-touchspin {
  width: auto;
  float: left;
  display: inline-block;
  @include box-shadow-light;

  input {
    &:focus {
      outline: none;
    }

    &.form-control,
    &.input-group {
      color: $gray-darker;
      background-color: white;
      height: $input-height;
      padding: 0.175rem 0.5rem;
      width: 3rem;
      border: $input-btn-border-width solid $input-border-color;
      float: left;
    }
  }

  .btn-touchspin {
    @extend .btn-default;
    background-color: white;
    border: $input-btn-border-width solid $input-border-color;
    height: $input-height / 2 + 0.0625rem;

    &:hover {
      background-color: $gray-light;
    }
  }

  .input-group-btn-vertical {
    color: $gray-darker;
    width: auto;
    float: left;

    .bootstrap-touchspin-up {
      border-radius: 0;
    }

    .bootstrap-touchspin-down {
      border-radius: 0;
    }

    .touchspin-up {
      &:after {
        content: "\E5CE";
      }
    }

    .touchspin-down {
      &:after {
        content: "\E5CF";
      }
    }

    i {
      top: 0.0625rem;
      left: 0.1875rem;
      font-size: 0.9375rem;
    }
  }
}

.custom-radio-end-label {
  margin-right: 15px;
  display: inline-block !important;
  cursor: pointer;
}

.cst-rdio {
  display: inline-block !important;
  position: relative;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-bottom: -3px;

  .cst-rdio-span {
    display: inline-block !important;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #ffffff;
    border-radius: 3px;
    border: solid 2px #7A7A7A;
  }

  .cst-rdio-span::after {
    position: absolute;
    content: '';
    left: 9px;
    top: 9px;
    height: 0;
    width: 0;
    border-radius: 3px;
    border: none;
  }

  input:checked ~ .cst-rdio-span {
    border-radius: 3px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: solid 2px #7A7A7A;
  }

  input:checked ~ .cst-rdio-span::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 4px;
    top: 1px;
    width: 6px;
    height: 9px;
    border: solid #04adcb;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }
}

.course-card {
  .course-card-wrapper {
    background-color: #f4f6fc;
    //width: 263px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 auto;
  }

  .course-card-top {
    flex: 1;
    position: relative;
    max-height: 330px;
    background-repeat: no-repeat;
    background-position: center;

    .crd-labels-wrapper {
      position: absolute;
      top: -35px;
      display: flex;
      font-size: 12px;
      color: #fff;
      justify-content: space-between;
      align-items: center;
      width: calc(100% + 10px);

      .crd-add-fav {
        width: 32px;
        height: 32px;
        background-color: #fbdc73;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .crd-icon {
          width: 18px;
        }
      }
    }

    > a {
      display: inline-block;
      width: 100%;
      height: 120px;
      background-size: cover;
      background-position: center;
    }

    img {
      width: 100%;
    }

    .course-card-title {
      overflow: hidden;
      max-height: 120px;
      position: absolute;
      bottom: -40px;
      font-size: 1.4rem;
      line-height: 2.2rem;

      > span {
        background-color: #003b5c;
        color: #fff;
        padding: 0.5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        font-family: NeoSansProMedium, sans-serif;
      }
    }
  }


  .course-card-details {
    //padding: 35px 40px 0;
    padding: 24px 24px 12px 24px;
    min-height: 82px;

    .course-info {
      display: flex;
      flex-direction: column;
      text-align: left;
      .course-location,
      .course-time {
        max-width: 100%;
        overflow: hidden;
        > span.crd-value {
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 19ch;
          display: inline-block;
        }
      }
    }

    span {
      font-size: 1.2rem;
      line-height: 24px;
      font-family: GalaxiePolarisBook, sans-serif;
      font-weight: bold;

      &.crd-icon {
        width: 15px;
        margin-right: 1rem;
      }
    }
  }

  .course-card-bottom {
    background-color: #00afd7;
    color: #fff;
    padding: 12px 24px;
    border-radius: 4px;
    .course-details {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-family: GalaxiePolarisBook, sans-serif;
      letter-spacing: 0.34px;
      font-weight: bold;
      line-height: 1.2rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      > svg {
        width: 20px;
        margin-top: 2px;
      }
    }
  }

  > div {
    background-clip: border-box;
  }


  .course-card-top.rand-1 {
    border-top: 54px solid #00AFD780;
    border-bottom: 24px solid #00AFD780;
    border-right: 24px solid #00AFD780;
    border-left: 24px solid #00AFD780;
  }

  .course-card-top.rand-2 {
      border-top: 54px solid #F3777280;
      border-bottom: 24px solid #F3777280;
      border-right: 24px solid #F3777280;
      border-left: 24px solid #F3777280;
    }


  .course-card-top.rand-3 {
      border-top: 54px solid #FBDC7380;
      border-bottom: 24px solid #FBDC7380;
      border-right: 24px solid #FBDC7380;
      border-left: 24px solid #FBDC7380;
    }


  &.training {
    //width: auto !important;
    box-shadow: none !important;
  }
}

// end of course card

.label {
  display: flex;
  align-items: center;
  padding: 2px 3px;
  font-family: NeoSansProRegular, sans-serif;
  font-size: 12px;
  line-height: 12px;
  transition: all .35s ease;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  max-height: 20px;
  width: fit-content;
  background-color: #00AED8;

  &.coral {
    background-color: #f37772;
    color: #fff;
  }

  &.white {
    background-color: #fff;
    color: #003b5c;
    font-weight: bold;
    height: 18px;
    padding: 0 5px;
    letter-spacing: .34px;
  }
}

.crd-icon {
  display: inline-block;
  height: 24px;
  font-size: 24px;
  margin: 0 auto;
  fill: #595959;
  width: 15px;
  margin-bottom: 3px;

  svg {
    width: 100%;
    height: 100%;
  }

  &.white {
    fill: #fff;
  }
}

.custom-checkbox {
  position: relative;

  input[type="checkbox"] {
    margin-top: 0.25rem;
    opacity: 0;
    cursor: pointer;
    position: absolute;

    + span {
      margin-right: 3px;
      display: inline-block;
      width: 0.9375rem;
      height: 0.9375rem;
      vertical-align: middle;
      cursor: pointer;
      border: 2px $gray-darker solid;

      .checkbox-checked {
        display: none;
        margin: -0.25rem -0.125rem;
        font-size: 1.1rem;
        color: $gray-darker;
      }
    }

    &:checked + span {
      .checkbox-checked {
        display: block;
      }
    }

    &:focus + span {
      border-color: $gray;
    }
  }

  label {
    text-align: left;
  }
}

.text-muted {
  font-size: 0.875rem;
}

.done {
  color: $brand-success;
  display: inline-block;
  padding: 0 0.8125rem;
  margin-right: 1.563rem;
}

.thumb-mask {
  > .mask {
    position: relative;
    width: 3.438rem;
    height: 3.438rem;
    overflow: hidden;
    border: $gray-light 1px solid;
    margin: $small-space 0;

    img {
      width: 55px;
      height: 55px;
    }
  }
}

.definition-list {
  dl {
    display: flex;
    flex-wrap: wrap;

    dt {
      font-weight: normal;
    }

    dd,
    dt {
      flex: 0 0 45%;
      background: $gray-light;
      padding: 0.625rem;
      margin: 0.125rem;

      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }
  }
}

.help-block {
  margin-left: 15px;
  margin-top: $small-space;
}

.btn.disabled,
.btn.disabled:hover {
  background: $gray;
}

.alert-warning {
  .material-icons {
    color: $warning;
    font-size: 2rem;
    margin-right: $small-space;
    padding-top: $extra-small-space;
  }

  .alert-text {
    font-size: 0.9375rem;
    padding-top: $small-space;
  }

  .alert-link {
    border-radius: 2px;
    border-width: 2px;
    margin-left: $small-space;
    padding: $extra-small-space $medium-space;
    font-weight: 600;
    font-size: 0.8125rem;
    color: $btn-tertiary-color;
  }

  ul li:last-child .alert-link {
    color: white;
  }

  .warning-buttons {
    margin-top: $extra-small-space;
  }
}

.btn-warning {
  @include transition(all .4s ease-in-out);
}

.crd-home-btn {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border: 1px solid;
  border-radius: 1.2rem;
  text-align: center;
  padding: 1.5rem 2.3rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-style: normal;
  font-family: GalaxiePolarisBook, sans-serif;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease;
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease;
  border-color: $crd-secondary-color;
  color: $crd-primary-color;
  background: none;

  &:hover {
    color: #fff;
    background: $crd-primary-color;
    border-color: $crd-primary-color;
  }

  //width: auto;
  //padding: 20px;
  //font-size: 23px;
  //background-color: #003b5c;
  //color: #fff;
  //transition: all .25s ease;
  //
  //&:hover {
  //  background-color: #00AEd8;
  //  color: #fff;
  //}
}

.btn-tertiary-outline {
  color: $btn-tertiary-color;
  background-image: none;
  background-color: transparent;
  border-color: $btn-tertiary-color;
  border: 0.15rem solid $btn-tertiary-color;
  @include transition(all .4s ease-in-out);

  &:hover {
    border-color: $btn-tertiary-hover;
    color: $btn-tertiary-hover;
  }
}

.alert {
  font-size: 14px;
}

.nav-item {
  .nav-link,
  .nav-separtor {
    color: $gray;
    font-weight: bold;

    &.active {
      color: $gray-darker;
    }
  }
}

.separator {
  margin: 0;
  border-color: rgba(0, 0, 0, 0.25);
}

// modal-dialog-centered class added from bootstrap 4.0.0, adapted for bootstrap 4.0.0-alpha.5
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (30px * 2)); // 30px correspond to the default margin size from bootstrap 4.0.0-alpha.5 for modal-dialog class
}

.d-flex.center-vertical {
  align-items: center;
}

p {
  font-size: 1.4rem;
}

.form-control {
  background: white;
  color: $light-gray;
  font-size: 1.4rem;
  border: 1px solid $input-border-color;
  padding: 1.3rem 1rem;
  border-radius: 3px;
}

.form-control-label {
  padding-top: 0;
}

.small-text {
  font-size: 1.1rem;
}

#module-crd_events-list {
  #wrapper {
    padding-bottom: 75px;
  }
}

.event-list {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  @media (min-width: 600px) {
    .left-filters {
      width: 230px;
      padding: 0;
    }
  }
  @media (max-width: 599px) {
    .left-filters {
      display: none;
    }
  }
  #trainings-display {
    @media (min-width: 600px) {
      width: calc(100% - 230px);
      padding: 0 0 0 1rem;
    }
    @media (max-width: 599px) {
      width: calc(100%);
    }
    margin: 0;
  }
}

#my-certificates {
  margin-bottom: 5rem;

  #trainings-display {
    border-left: none;

    span {
      flex-basis: 50%;
    }

    .event-name-head {
      flex-basis: 100%;
    }

    .training:hover {
      cursor: none;
    }

    .list-display {
      div {
        flex-basis: 50%;
      }

      .list-location {
        flex-basis: 100%;

        span {
          width: 400px;
        }
      }

      .online-link {
        i {
          font-size: 25px;
          color: #00AED8;
        }
      }
    }
  }

  #scrollToTop {
    display: none;
    position: fixed;
    bottom: 290px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #00AED8;
    color: #ffffff;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;

    &:hover {
      background-color: #07adcb;
    }
  }
}

.sponsor .sponsor-container {
  text-align: center;
}

.name-container {
  position: relative;

  #name-dropdown-trigger, .fa-chevron-down {
    cursor: pointer;
  }

  #name-dropdown-trigger {
    line-height: 2rem;
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  #name-dropdown-icon {
    position: absolute;
    top: 5px;
    right: -15px;
  }

  #name-dropdown {
    position: absolute;
    margin-top: 5px;
    box-shadow: 0px -2px 5px 0px rgba(170, 170, 204, 0.77);
    background: #ffffff;
    text-align: left;
    z-index: 10;
    padding: 10px 0 10px 0;

    li a {
      padding: 10px 25px;
      width: auto !important;
    }

    li:hover {
      background: #E3F4FB;
    }
  }
}

#header .header-nav #_desktop_language_selector {
  margin-left: 40px;
}

.form-group {
  .label, label {
    &.required {
      &:before {
        content: '*';
      }
    }
  }

  &.form-error {
    label {
      color: #F13340;
    }

    input, textarea {
      border: 1px solid #f13340;
      color: #f13340;
      background: url(../img/form-error.png) 98% 14px no-repeat #fff1f2;
    }

    .form-control {
      padding-right: 30px;
    }
  }

  &.form-ok {
    label {
      color: #35B33F;
    }

    input, textarea {
      border: 1px solid #46a74e;
      color: #35b33f;
      background: url(../img/form-ok.png) 98% 14px no-repeat #ddf9e1;
    }

    .form-control {
      padding-right: 30px;
    }
  }

  .form-control:focus {
    border-color: inherit;
    box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
  }

}

#footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.hidden {
  display: none;
}

@media (min-width: 768px) {
  #_desktop_user_info {
    margin-left: 20px;
    margin-bottom: 2px;
  }
  #_desktop_language_selector {
    margin-right: 60px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1190px;
  }
}

@media (max-width: 1200px) {
  #_desktop_language_selector {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  #header .header-nav {
    min-height: 230px;

    #_desktop_logo {
      text-align: center;
    }

    .user-info {
      .logout {
        display: block;
        text-align: right;
        margin-top: 5px !important;
      }
    }

    #search_widget {
      position: absolute;
      bottom: -120px;
      width: 80%;
      margin-left: 7%;
    }

    #_desktop_user_info {
      position: absolute;
      bottom: -56px;
    }

    #_desktop_top_menu {
      position: absolute;
      right: 25px;
      bottom: -52px;
    }

    #_desktop_language_selector {
      width: auto !important;
      position: absolute;
      right: 72px;
      bottom: -46px;
    }
  }


}

@media(max-width: 450px) {
  #header .header-nav {
    #_desktop_user_info {
      .user-info-logged {
        margin-bottom: 11px;
        font-size: 12px;
      }

      .user-info {
        a {
          line-height: 1.6rem !important;
          width: 8rem !important;
          font-size: 12px;
        }
      }
    }

    #search_widget {
      input[type=text] {
        padding: 10px;
      }
    }

    #_desktop_language_selector {
      padding: 0 0 0 10px;
      position: absolute;
      bottom: -50px;

      button {
        font-size: 12px;
      }

    }

    #_desktop_top_menu {
      position: absolute;
      bottom: -56px;
    }
  }

}

@media (min-height: 1365px) {
  #authentication {
    #wrapper {
      min-height: 968px;
    }
  }
}

#cat-box-1,
#cat-box-2,
#cat-box-3,
#cat-box-4 {
  font-family: NeoSansProMedium, sans-serif;
  font-weight: 300;
}