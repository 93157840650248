@font-face {
  font-family: 'GalaxiePolarisBook';
  src: url('../fonts/GalaxiePolaris-Bold.eot');
  src: local('☺'), url('../fonts/GalaxiePolaris-Bold.woff') format('woff'), url('../fonts/GalaxiePolaris-Bold.ttf') format('truetype'), url('../fonts/GalaxiePolaris-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GalaxiePolarisBook';
  src: url('../fonts/GalaxiePolaris-Book.eot');
  src: local('☺'), url('../fonts/GalaxiePolaris-Book.woff') format('woff'), url('../fonts/GalaxiePolaris-Book.ttf') format('truetype'), url('../fonts/GalaxiePolaris-Book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansProMedium';
  src: url('../fonts/NeoSansPro-Medium.eot');
  src: local('☺'), url('../fonts/NeoSansPro-Medium.woff') format('woff'), url('../fonts/NeoSansPro-Medium.ttf') format('truetype'), url('../fonts/NeoSansPro-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansProMedium';
  src: url('../fonts/NeoSansPro-Light.eot');
  src: local('☺'), url('../fonts/NeoSansPro-Light.woff') format('woff'), url('../fonts/NeoSansPro-Light.ttf') format('truetype'), url('../fonts/NeoSansPro-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
