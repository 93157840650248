.dropdown {
  color: $gray;
  font-size: 1.4rem;

  &:hover {
    .expand-more, .fas {
      color: $brand-primary;
    }
  }

  .expand-more {
    color: $link-color;
    cursor: pointer;
    user-select: none;

  }

  .active {
    max-height: 200px;
    overflow-y: hidden;
    visibility: visible;
  }

  select {
    -moz-appearance: none;
    border: 0 none;
    outline: 0 none;
    color: $gray-darker;
    background: white;
  }
}

.dropdown-item:focus, .dropdown-item:hover {
  background: none;
  text-decoration: none;
  color: $brand-primary;
}

