.top-menu {
  margin-bottom: 0;
  font-family: $headingFontFamily, sans-serif;
  font-size: 14px;
  &:after {
    clear: left;
  }
  &[data-depth="0"] {
    > li {
      display: inline-block;
      text-align: center;
      > a:after {
        content: '';
        border-right: 1px solid $menu-border;
        position: absolute;
        right: 2px;
        height: 36px;
        top: 8px;
      }
      &:last-of-type {
        > a:after {
          content: none;
        }
      }
    }
    > li.has-children {
      &.cms-category {
        position: relative;
        .sub-menu {
          margin-left: 0;
          width: calc(100%);
          min-width: calc(100%);
          padding-left: 40px;
          .top-menu[data-depth="1"] {
            margin: 20px 0;
          }
          .cms-page {
            border: none;
            a {
              font-weight: 300;
              padding-bottom: 20px;
              &:after {
                display: none;
              }
              &:hover {
                color: $crd-secondary-color !important;
              }
            }
            &:last-child {
              a {
                padding-bottom: 0;
              }
            }
          }
        }
      }
      a[data-depth="0"] {
        padding-right: 40px;
      }
    }
  }
  &[data-depth="1"] {
    margin: rem(10px);
  }
  a:not([data-depth="0"]) {
    display: block;
    padding: rem(10px);
    color: $gray;
    font-weight: 400;
  }
  a.dropdown-submenu {
    color: $gray-darker;
  }

  a[data-depth="0"] {
    font-weight: normal;
    text-transform: uppercase;
    color: $crd-primary-color !important;
    padding: 18px 13px;
    position: relative;
    &:hover {
      #header & {
        color: $crd-secondary-color;
        text-decoration: none;
      }
    }
    .icon-chevron {
      position: absolute;
      font-size: 18px;
      transform: rotate(90deg);
      width: 19px;
      height: 19px;
      top: 16px;
      right: 30px;
    }
    .icon-menu {
      position: absolute;
      left: 0;
      top: 13px;
      font-size: 22px;
    }
  }
  a[data-depth="1"],
  a[data-depth="2"] {
    padding: 0 rem(10px) rem(10px) 0;
  }
  .collapse {
    display: inherit;
  }
  .sub-menu {
    &.collapse {
      display: none;
    }
    box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, 0.1);
    border: none;
    margin-left: rem(15px);
    width: calc(100% - 30px);
    min-width: calc(100% - 30px);
    z-index: 18;
    display: none;
    a:hover {
      color: $brand-primary;
    }
  }
  .popover {
    max-width: inherit;
    .top-menu[data-depth="2"] {
      padding: 0 7px;
      margin: 9px 0;
      -ms-box-orient: horizontal;
      display: flex;
      flex-direction: row;
      max-height: 760px;
      flex-wrap: wrap;
      overflow: auto;
      > li {
        padding: 5px;
        width: calc(25% - 20px);
        margin: 10px;
        > a:after {
          content: "";
          border-bottom: 1px solid $gray-light2;
          width: 35px;
          display: block;
          padding-top: 6px;
        }
      }
    }
    .top-menu[data-depth="1"] {
      > li.category {
        > a {
          padding: 10px 0 10px 22px;
          color: $crd-primary-color !important;
          font-size: 18px;
        }
        //> a:after {
        //  content: "";
        //  border-bottom: 1px solid $gray-light2;
        //  width: 35px;
        //  display: block;
        //  padding-top: 6px;
        //}
      }
    }
  }
}

#_desktop_top_menu {
  .category a[data-depth="2"].dropdown-item:hover {
    color: $crd-secondary-color;
    text-decoration: underline;
  }
  .category a[data-depth="3"].dropdown-item {
    font-family: NeoSansProMedium, sans-serif;
    font-weight: 300;
    font-size: 12px;
    padding: 3px 0;
    color: #8b8b8b;
    &:hover {
      color: $crd-secondary-color;
      text-decoration: underline;
    }
  }
  .category a[data-depth="3"] {
    //display: none;
  }
}

.popover.bs-tether-element-attached-top {
  margin-top: 0;
}

#mobile_top_menu_wrapper {
  margin-top: rem(10px);
  padding-bottom: rem(10px);
  background: white;
  #top-menu {
    margin-bottom: rem(10px);
  }
  .top-menu {
    color: $gray-darker;
    .collapse-icons[aria-expanded="true"] {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }
    .collapse-icons .remove {
      display: none;
    }
    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
    a[data-depth="0"] {
      padding: rem(10px);
      border-bottom: 1px solid $gray-lighter;
    }
    .collapse {
      display: none;
      &.in {
        display: block;
      }
    }
    .collapse.show {
      display: block;
    }
    .sub-menu {
      &.collapse {
        display: none;
        &.in {
          display: block;
        }
      }
      &.collapse.show {
        display: block;
      }
      box-shadow: none;
      z-index: inherit;
      display: block;
      position: static;
      overflow: hidden;
      margin-left: 0;
      width: 100%;
      min-width: 100%;
      background: $gray-lighter;
      ul[data-depth="0"] > li {
        border-bottom: 1px solid $gray;
      }
      ul[data-depth="1"] {
        margin: 0;
        > li {
          float: none;
          margin: 0;
          a {
            text-transform: none;
          }
        }
      }
      ul {
        padding: 0;
      }
      li > a {
        padding: rem(10px);
        border-bottom: 1px solid white;
        font-weight: bold;
      }
      ul[data-depth="2"] li a {
        padding-left: rem(20px);
      }
      ul[data-depth="3"] li a {
        padding-left: rem(40px);
      }
      ul[data-depth="4"] li a {
        padding-left: rem(60px);
      }
    }
    .popover {
      border-radius: 0;
    }
  }
  .js-top-menu-bottom {
    a {
      color: $gray;
    }
    .language-selector-wrapper {
      padding: rem(10px);
      .language-selector {
        display: inline;
      }
    }
    .currency-selector {
      padding: rem(10px);
    }
    #contact-link {
      padding: rem(10px);
    }
    .user-info {
      padding: 0 rem(10px);
      a {
        padding: rem(10px) 0;
        display: block;
        width: 100%;
      }
    }
  }
}

.nav-top {
  .block-social {
    display: inline-block;
    width: 140px;
    max-width: 140px;
    float: right;
    text-align: right;
    ul {
      margin-bottom: 0;
      padding: 17px 0;
      li {
        width: 30px;
        background: none;
        height: auto;
        margin: 0;
        &:hover {
          background: none;
        }
      }
    }
  }
}

#header .block-social ul li a {
  color: #c8c8c8;
  line-height: 13px;
  font-size: 14px;
  &:hover {
    color: $crd-primary-color;
  }
}

.icon-menu.tablet-menu {
  display: none;
}

.expandable-menu.top-menu {
  .sub-menu {
    width: auto;
    min-width: 533px;
  }
  li{
    .popover {
      .top-menu {
        &[data-depth="2"] li:first-of-type{

        }
        &[data-depth="1"] {
          margin: 20px 0px;
          > li {
            > a {
              //display: none;
              font-size: 14px;
            }
          }
        }
        &[data-depth="2"] {
          display: block;
          padding: 0;
          max-width: 50%;
          border-right: 1px solid $gray-light;
          > li {
            width: 100%;
            display: block;
            margin: 0;
            padding: 0 45px 10px;
            cursor: pointer;
            font-size: 12px;
            &:hover {
              .collapse {
                display: block;
              }
            }
            > a {
              padding: 0;
              &:after {
                content: none;
              }
            }
          }
          .collapse {
            /* display: none;*/
            position: absolute;
            left: calc(50% + -1px);
            max-width: calc(100% - 52px);
            top: 15px;
            padding-left: 50px;
            border-left: 1px solid #f1e8e8;
          }
        }
        &[data-depth="3"] {

          > li {

          }
        }
      }
    }
  }
}

#_desktop_top_menu {
  .expandable-menu.top-menu {
    .category a[data-depth="2"].dropdown-item {
      margin-top: 5px;
      font-size: 13px;
      white-space: normal;
      &:hover {
        text-decoration: none;
      }
      &.active {
        text-decoration: none;
        background: none;
        color: $crd-secondary-color;
        &:after {
          content: "";
          border: 0;
          border-right: 3px solid $crd-secondary-color;
          position: absolute;
          left: calc(50% - 3px);
          height: 26px;
          width: 1px;
          display: inline-block;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .nav-top {
    .block-social {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .top-menu {
    &[data-depth="0"] {
      > li {
        float: none;
        width: 100%;
        text-align: left;
        a {
          &:after {
            content: none;
          }
        }
      }
    }
    .popover {
      > .top-menu {
        display: block;
        max-height: none;
        height: initial;
        > li {
          padding: 0;
          width: 100%;
          a {
            &:after {
              content: none;
            }
          }
        }
      }
      .top-menu[data-depth="2"] > li {
        padding-right: 0;
      }
    }
    .icon-chevron, .icon-menu {
      display: none;
    }
  }

  #mobile_top_menu_wrapper {
    .sub-menu {
      li {
        a, a:hover {
          color: $brand-primary;
        }
      }
    }
  }

  .js-top-menu-bottom {
    #contact-link {
      .material-icons {
        line-height: 20px;
        margin-right: 4px;
        display: inline-block;
      }
    }
  }

  .top-menu .popover .top-menu[data-depth="2"] > li {
    width: 100%;
    margin: 0;
  }

  .top-menu .popover .top-menu[data-depth="2"] > li > a:after {
    display: none;
  }

  .top-menu .popover .top-menu[data-depth="1"] > li.category > a {
    font-size: 14px;
    line-height: 21px;
  }

  #mobile_top_menu_wrapper .top-menu .sub-menu {
    padding-left: 0;
  }

  #mobile_top_menu_wrapper {
    #_mobile_user_info {
      margin-top: 16px;
      a:focus {
        text-decoration: none;
      }
      .user-info {
        a {
          padding: 0;
          color: $gray-darker;
        }
        .btn-primary {
          color: white;
          max-width: 150px;
        }
        #header-login-content {
          position: static;
          width: 100%;
          border: none;
          box-shadow: none;
          padding: 15px 0;
          li li:hover {
            background: inherit;
          }
        }
        .header_user_info {
          > a {
            color: $crd-primary-color;
          }
        }
      }
    }
  }

  .expandable-menu {
    &.top-menu {
      li {
        .popover {
          .top-menu[data-depth="1"] > li > a {
            display: block;
          }
          .top-menu[data-depth="2"] {
            max-width: none;
            display: flex;
            border: none;
            max-height: none;
            > li {
              padding: 5px 0 5px 5px;
            }
            .collapse {
              position: relative;
              left: 0;
              max-width: none;
              top: 0;
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .top-menu .dropdown-item.active {
    background: none;
  }
}

.popover{
  left: initial!important;
}
@media (min-width: 768px) and (max-width: 1100px) {
  .top-menu[data-depth="0"] {
    > li a[data-depth="0"] {
      padding: 18px 55px 18px 0;
      display: inline-block;
      width: inherit;
      .icon-menu {
        display: none;
      }
    }
  }
  .icon-menu.tablet-menu {
    display: inline-block;
    margin-right: 20px;
    color: $crd-primary-color;
    margin-top: -4px;
    font-size: 22px;
  }
}