$gray-dark: #363A42;
$gray-darker: #232323;
$gray: #7A7A7A;
$gray-light: #F1F1F1;
$gray-light-second: #DBDBDB;
$gray-lighter: #f6f6f6;
$gray-lighter-second: #F7F7F7;
$brand-primary: #07adcb;
$brand-secondary: #f39d72;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$brand-info: #2fb5d2;
$label-color: $gray-darker;
$btn-primary-color: #fff;
$btn-primary-bg: #00AED8;
$btn-primary-hover-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 20px;
$btn-line-height: 1.6rem;
$btn-padding-top: 0.6rem;
$btn-padding-bottom: 0.8rem;
$btn-padding: 6px 10px 12px 10px;
$input-border-color: #dcdcdc;
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.3125rem;
$small-space: 0.625rem;
$medium-space: 1.25rem;
$large-space: 1.875rem;
$extra-large-space: 2.5rem;
$warning: #FF9A52;
$warning-second: #F49D6E;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255, 154, 82, 0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1.4rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;
$font-size-h1: 1.375rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: 0.9375rem;
$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
$text-muted: $gray;
$mainFontFamily: 'GalaxiePolarisBook';
$headingFontFamily: 'NeoSansProMedium';
$mainFontFamily-bold: 'GalaxiePolarisBook';
$mainFontFamily-light: 'GalaxiePolarisBook';
$placeholder-color: #cfcfcf;
$link-color: #3cb7e0;
$content-color: #333;
$primary-background: #19547e;
$font-size-default: 1.4rem;
$dark-gray: #333333;
$light-gray: #777777;
$highlight-color: #04adcb;
$light-bkg: #FAFAFA;
$content-border: #e3e3e3;
$light-icon: #cccccc;
$list-before: #e1f5f9;
$language-bkg: #fef7eb;
$language-bkg: #fef7eb;
$alert-success-no-bkg: #05734a;
$nav-background-color: #185381;
$nav-links-color: #fff;
$crd-primary-color: #003B5C;
$crd-secondary-color: #3cb7e0;
$prod-title: #000;
$menu-border: #2168b6;
$gray-light2: #e6e6e6;
$background-blue: #D9E4EF;
$border-gray: #D1D1D1;

$h2-font-size: 2.5rem;

$link-hover-color: #003B5C !default;;