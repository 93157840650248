.footer-container {
  padding: 4.5rem 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  img.logo {
    max-width: 150px;
  }
  .footer-left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .links {
    &.block-contact {
      .contact-section {
        background: none;
        padding: 0;

        > .container {
          background: #303030;
          color: $nav-links-color;
          padding-top: 20px;
          padding-bottom: 20px;

          .col-sm-3, .col-sm-4 {
            text-align: left;
          }

          .store-info-title {
            font-size: 12px;
            line-height: 18px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 6px;
          }

          .padded-left .contact-details {
            display: flex;
            align-items: center;
            justify-content: left;

            a {
              color: #fff;
              width: 36px;
              height: 36px;
              line-height: 34px;
              border: 1px solid #fff;
              border-radius: 18px;
              display: inline-block;

              &.phone {
                background: url('../img/icons/phone-icon.svg') no-repeat 9px center;
                margin-right: 15px;
              }

              &.email {
                background: url('../img/icons/email-icon.svg') no-repeat 9px center;
              }

              span.value {
                width: 0;
                display: none;
                padding-left: 36px;
                padding-right: 9px;
                -webkit-transition: width 1s ease-in-out;
                -moz-transition: width 1s ease-in-out;
                -o-transition: width 1s ease-in-out;
                transition: width 1s ease-in-out;
              }

              &.show {
                width: auto;

                span.value {
                  width: auto;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }

    &.footer-links {
      .title {
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 16px;
      }
      .col-md-3.wrapper {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 3rem;
        @media (min-width: 768px) {
          &:last-child, &:nth-last-child(2) {
            margin-bottom: 0;
          }
        }
        @media (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .navbar-toggler {
      color: $nav-links-color;
      display: none;
    }

    p.h3 {
      font-size: 1.6rem;
      color: $crd-primary-color;
      font-weight: 700;
      font-family: "GalaxiePolarisBook", sans-serif;
      margin-bottom: 0.8rem;
    }

    ul {
      display: inherit;
      justify-content: center;
      padding: 0;
      margin: 0;
    }

    li {
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 18px;
      a {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $crd-secondary-color;
        font-family: "GalaxiePolarisBook", sans-serif;
        &:hover {
          color: $crd-primary-color;
        }

        &:focus {
          text-decoration: none;
        }
      }

      &:after {
        display: none;
      }

      &:last-of-type:after {
        content: none;
      }

      .current-page {
        text-decoration: none;
      }
    }
  }

  .footer-border {
    border-bottom: 0;
  }

  .block-newsletter {
    background: #2E5989;
    font-size: 12px;
    line-height: 18px;
    padding: 16px 0;
    text-align: center;
    width: 100%;
  }

  .block-social {
    width: auto;
    text-align: left;
    ul {
      margin: 0;
      padding-bottom: 0;

      li {
        background: none;
        height: 34px;
        width: 34px;
        background: $crd-primary-color;
        border-radius: 50%;
        text-align: center;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background: $crd-secondary-color;
        }
        a {
          color: #fff;
          font-size: 17px;
          line-height: 29px;
        }
      }
    }
  }

  .copyright {
    font-size: 1.2rem;
    color: $crd-primary-color;
    line-height: 2.4rem;
    margin-top: 10px;
  }
}

@include media-breakpoint-down(sm) {
  .footer-container {
    .footer-left {
      padding-bottom: 4rem;
      .block-social {
        padding-top: 2rem;
      }
    }
  }
}

@media (max-width: 450px) {
  .float-sm-right {
    float: right!important;
  }
  .block_newsletter .input-group {
    width: calc(100% - 30px);
  }
}
