@import "../external/inc/variables";
@import "../external/inc/arrows";

#my-account, #module-crd_certificates-list, #module-crd_events-panelist {
  #left-column .block-categories {
    display:none;
  }
  #my-certificates #trainings-display .list-display .list-location span {
    width: 280px;
  }
  #wrapper{
    padding-top: 38px;
    padding-bottom: 38px;
    background: #f6f6f6;
    @media(min-widtH: 1024px) {
      min-height: calc(100vh - 491px);
    }
    .container {
      @media(min-width:1200px) {
        max-width: 1440px;
      }
    }
  }
}

@media (min-width: 811px) {
  #my-account,
  #module-crd_certificates-list,
  #module-crd_events-panelist{
    #left-column.col-md-3 {
      min-width: 240px;
      max-width: 240px;
    }

    #content-wrapper.col-md-9 {
      max-width: calc(100% - 240px);
    }

    #left-column {
      h2 {
        padding: 15px 0 0 10px;
        margin: 0 0 20px 0;
        font-size: 12px;
        font-weight: bold;
        font-family: GalaxiePolarisBook;
        color: #666;
        text-transform: uppercase;

        &:after {
          display: none;
        }
      }
    }
  }
}

.account-left-menu {
  li {
    padding: 0;

    a {
      display: inline-block;
      width: 100%;
      font-size: 12px;
      font-family: NeoSansProMedium, sans-serif;
      color: $crd-primary-color;
      font-weight: 500;
      padding: 0 10px;
      line-height: 30px;

      &.active {
        color: $gray;
        background: $background-blue;
        border-radius: 4px;
      }

      &:before {
        content: '';
        display: none;
      }
    }
  }
}

.my-account-buttons {
  margin-left: 35px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    padding: 20px 30px 20px 50px;
    margin: 0;
    border-bottom: 1px solid $border-gray;
  }
  @media (min-width: 768px) {
    padding: 0 0 20px 0;
  }

  .back-btn {
    color: #2A527E;
    border: none;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 14px;
    }

    &:hover {
      text-decoration: underline;
    }

    &:before {
      @include arrow;
      border-right: none;
      border-bottom: none;
      @include vendor-prefix("transform", rotate(180deg));
      top: 4px;
      margin-bottom: auto;
      left: -20px;
    }
  }

  .reorder-btn {
    font-size: 12px;
    margin: 0;
    text-transform: none;
    height: 30px;
    line-height: 29px !important;
  }
}

@media (max-width: 767px) {
  #my-account,
  #module-crd_certificates-list,
  #module-crd_events-panelist{
    main {
      background: #fff;
    }

    #wrapper {
      padding: 0;
      background: #fff;
    }

    #footer {
      margin-top: 0;
      padding-top: 0;
    }

    #left-column {
      h2 {
        margin: 10px 0 0 10px;
      }
    }

    .account-left-menu {
      li {
        line-height: inherit;
        margin-bottom: 10px;

        a {
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  #my-account, #module-crd_certificates-list, #module-crd_events-panelist {
    #content-wrapper.col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .mm-list > li.my-account a.mm-subopen {
      width: 100%;
    }
    #content {
      .row.w-100 {
        width: calc(100% + 30px) !important;
      }
    }
  }
  #my-account #left-column.col-md-3, #module-crd_certificates-list, #module-crd_events-panelist #left-column.col-md-3{
    display: none;
  }
}
