#event-registration {
  .form-control {
    padding: 0;
  }
}
#registration-success{
  position: fixed;
  top: 0px;
  z-index: 100000;
  width: 100%;
  text-align: center;
  padding: 25px 0 26px 0;
  left: 0;
}

#module-crd_participants-registration {
  #wrapper {
    background: #f2f2f2;
    padding-bottom: 75px;
  }

  #event-registration {
    form .title {
      text-align: center;
      font-weight: normal;
      margin: 30px auto;
      padding: 15px;
      position: relative;
      font-size: 26px;
      letter-spacing: 1.3px;
      line-height: 1.85;

      &:after {
        content: '';
        border-bottom: solid 2px #07adcb;
        width: 10%;
        position: absolute;
        bottom: 0;
        left: 45%;
      }
    }

    .subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 0.8px;
      position: relative;
      padding: 20px 0;
      margin-bottom: 40px;

      &:after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: solid 1px #cccccc;
      }
    }

    label {
      font-size: 14px;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #333333;
    }

    #id_event, #profession, #invoice-country, #medical_chamber {
      height: 45px;
      border-radius: 5px;
      border-width: 0.5px;
      border-color: #ffffff;
      color: #777777;
      padding: 10px;

      &:focus {
        box-shadow: none;
        border: solid 0.5px #04adcb;
        border-radius: 5px;
        outline: none;
      }
    }

    input[type=text], input[type=email] {
      height: 45px;
      border-radius: 5px;
      border-width: 0.5px;
      border-color: #ffffff;
      color: #777777;
      padding: 10px;

      &:focus {
        box-shadow: none;
        border: solid 0.5px #04adcb;
        border-radius: 5px;
        outline: none;
      }
    }

    .salutation-label {
      margin-right: 35px;
    }

    .custom-radio-label {
      margin-right: 15px;
    }

    .salutation-checkbox {
      opacity: 0;
      position: absolute;
      cursor: pointer;
    }

    .address-note {
      margin-bottom: 15px;
      font-size: 12px;
      color: #777;
    }

    .marketing-cookies-message {
      text-align: center;
      font-weight: bold;
      padding: 10px 0;
      background: #00AED8;
      display: none;
    }

    .form-group {
      position: relative;

      .pointer-label {
        margin-left: 37px;
      }

      .checkbox-label {
        display: inline-block;
        cursor: pointer;
        height: 18px;
        width: 18px;
        position: absolute;
        top: 6px;

        &:not(.for-radio) {
          margin-right: 15px;
        }

        .checkbox-custom {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: #ffffff;
          border-radius: 3px;
          border: none;
        }

        .checkbox-custom::after {
          position: absolute;
          content: '';
          left: 9px;
          top: 9px;
          height: 0;
          width: 0;
          border-radius: 3px;
          border: none;
        }

        input:checked ~ .checkbox-custom {
          background-color: #04adcb;
          border-radius: 3px;
          -webkit-transform: rotate(0deg) scale(1);
          -ms-transform: rotate(0deg) scale(1);
          transform: rotate(0deg) scale(1);
          opacity: 1;
          border: none;
        }

        input:checked ~ .checkbox-custom::after {
          -webkit-transform: rotate(45deg) scale(1);
          -ms-transform: rotate(45deg) scale(1);
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: 6px;
          top: 3px;
          width: 6px;
          height: 9px;
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }
      }
    }


    #invoice-details {
      display: none;
    }

    #diff-invoice:checked #invoice-details {
      display: block;
    }

    #message-text {
      border: solid 0.5px #ffffff;
      border-radius: 5px;

      &:focus {
        outline: none;
        border: solid 0.5px #04adcb;
        box-shadow: none;
      }
    }

    .pointer-label {
      cursor: pointer;
    }

    .orange-button {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      background: #00AED8;
      color: #ffffff;
      text-align: center;
      padding: 20px 0;
      border: none;
      border-radius: 30px;

      &:focus {
        outline: none;
      }

      &:hover {
        background: #07adcb;
      }

      &:active {
        background: #0062cc;
      }
    }
  }
}
