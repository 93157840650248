@media (max-width: 767px) {
  #training-listing {
    .filters-container {
      flex-direction: column;
      padding: 0 !important;

      .nice-select {
        width: 100%;
        border-right: none !important;
      }
    }
  }
}
