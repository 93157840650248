/*
	jQuery.mmenu CSS
*/
@import "inc/variables";


//	Animations
html.mm-opened
{
	.mm-page,
	#mm-blocker,
	.mm-fixed-top,
	.mm-fixed-bottom,
	.mm-menu.mm-horizontal > .mm-panel
	{
		@include vendor-prefix( "transition", none $transitionDuration $transitionFunction );

		// border is needed to force transitionend event even if no other value changed
		@include vendor-prefix( "transition-property", ( top, right, bottom, left, border ) );
	}
	.mm-page,
	#mm-blocker
	{
		left: 0%;
		top: 0;
		margin: 0;
		border: 0px solid rgba( 0, 0, 0, 0 );
	}
	
	&.mm-opening
	{
		.mm-page,
		#mm-blocker
		{
			border: 0px solid rgba( 100, 100, 100, 0 );
		}
	}
}

//	Generic classes
.mm-menu .mm-hidden:not(.test)
{
	display: none;
}

//	Fixed elements
.mm-fixed-top,
.mm-fixed-bottom
{
	position: fixed;
	left: 0;
}.mm-menu .mm-list > li > a.mm-subclose
.mm-fixed-top
{
	top: 0;
}
.mm-fixed-bottom
{
	bottom: 0;
}


//	Menu, submenus, items
html.mm-opened .mm-page,
.mm-menu > .mm-panel
{
	@include vendor-prefix( "box-sizing", border-box );
}

// html/body
html.mm-opened,
html.mm-opened body
{
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
}

// page + blocker
html.mm-opened .mm-page
{
	height: 100%;
	overflow: hidden;
	position: absolute;
}
html.mm-background .mm-page
{
	background: inherit;
}
#mm-blocker
{
	background: #fff;
	opacity: 0;
	display: none;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 999;
}
html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker
{
	display: block;
}

// menu
.mm-menu.mm-current
{
	display: block;
}
.mm-menu
{
	background: inherit;
	display: none;
	overflow: hidden;
	height: 100%;
	padding: 0;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	
	> .mm-panel:not(.test)
	{
		background: inherit;
	
		-webkit-overflow-scrolling: touch;
		overflow: scroll;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		padding: 0;
		position: absolute;
		top: 0;
		left: 100%;
		z-index: 0;
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}
		
		&.mm-opened
		{
			left: 0%;
		}
		&.mm-subopened
		{
			left: -$subOffset;
		}
		&.mm-highest
		{
			z-index: 1;
		}
		&.mm-hidden:not(.test)
		{
			display: block;
			visibility: hidden;
		}
	}
}

//	Lists
.mm-menu
{
	.mm-list
	{
		padding: 0 0 ( $btnSize / 2 ) 0;
	}
	> .mm-list
	{
		padding: ( $btnSize / 2 ) 0 $btnSize 0;
	}
}
.mm-panel > .mm-list
{
	margin-left: 0;
	margin-right: 0;
	
	&:first-child
	{
		padding-top: 0;
	}
}

.mm-list,
.mm-list > li
{
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
}

.mm-list > li:not(.mm-subtitle):not(.my-account) {
	border-bottom: 1px solid #F5F5F5;
	&:first-child {
		border-top: 1px solid #F5F5F5;
	}
}
ul[data-depth="0"].mm-list > li:not(.my-account) > a,
ul[data-depth="0"].mm-list > li:not(.my-account) > span {
	height: 60px;
	line-height: 65px;
	text-transform: uppercase;
	padding: 0 ( $padding * 2 ) 0 ( $padding * 4 );
}
.mm-list
{
	font: inherit;
	font-size: $fontSize;

	a,
	a:hover
	{
		text-decoration: none;
	}

	> li:not(.my-account)
	{
		position: relative;

		> a:not(.mm-subclose),
		> span:not(.mm-subclose)
		{
			@include ellipsis;
			color: inherit;
			height: 45px;
			line-height: 29px;
			display: block;
			padding: $padding ( $padding * 2 ) $padding ( $padding * 4 );
			margin: 0;
			width: 90%;
		}
	}

	> li.my-account
	{
		> a:not(.mm-subclose),
		> span:not(.mm-subclose)
		{
			@include ellipsis;
			line-height: 35px;
			padding: $padding ( $padding * 2 ) $padding ( $padding * 4 );
			margin: 0;
			display: block;
		}
		a.mm-subopen:after {
			margin-bottom: -10px;
		}
	}
	
	> li:not(.mm-subtitle):not(.mm-label):not(.mm-noresults)
	{
		@include border-bottom;
		&:after
		{
			width: auto;
			margin-left: ( $padding * 2 );
			position: relative;
			left: auto;
		}
	}

	// subopen/close
	a.mm-subopen
	{
		@include border-left;

		width: $btnSize;
		height: 100%;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
		
		&.mm-fullsubopen
		{
			width: 100%;
			
			&:before
			{
				border-left: none;
			}
		}

		+ a,
		+ span
		{
			//padding-right: ( $padding / 2 );
			//margin-right: $btnSize;
		}
	}

	> li.mm-selected
	{
		> a.mm-subopen
		{
			background: transparent;
		}
		> a.mm-fullsubopen
		{
			+ a,
			+ span
			{
				padding-right: $btnSize + ( $padding / 2 );
				margin-right: 0;
			}
		}
	}
	
	a.mm-subclose
	{
		text-indent: $padding * 2;
		padding: 0  0 0 ($padding * 6);
		margin-top: 0;
		line-height: ($btnSize + $padding);
	}

	// Labels
	$labelSize: ( $btnSize / 2 ) + ( $padding / 2 );
	> li.mm-label
	{
		@include ellipsis;
		font-size: 10px;
		text-transform: uppercase;
		text-indent: $padding * 2;
		line-height: $labelSize;
		padding-right: $padding / 2;
	}

	// Spacers
	> li.mm-spacer
	{
		padding-top: $btnSize;
		
		&.mm-label
		{
			padding-top: $labelSize;
		}
	}

	// Arrows
	a.mm-subopen:after,
	a.mm-subclose:before
	{
		@include arrow;
	}
	a.mm-subopen:after
	{
		@include arrow-next;
		right: 30px;
	}
	a.mm-subclose:before
	{
		@include arrow-prev;
		margin-bottom: -5px;
		left: ($padding * 4);
	}
}

// vertical submenu
.mm-menu.mm-vertical .mm-list
{
	.mm-panel
	{
		display: none;
		padding: $padding 0 $padding $padding;
		
		li:last-child:after
		{
			border-color: transparent;
		}
	}
	li.mm-opened > .mm-panel
	{
		display: block;
	}
	> li.mm-opened > a.mm-subopen
	{
		height: $btnSize;
		&:after
		{
			@include vendor-prefix( "transform", rotate( 45deg ) );
			top: 16px;
			right: 16px;
		}
	}
}

@include colors;
@include sizing;



//	Bugfix for browsers without support for overflowscrolling
//		- Android < 3

html.mm-nooverflowscrolling.mm-opened
{
	height: auto;
	overflow: auto;
	overflow-x: hidden;
	overflow-y: scroll;

	body
	{
		overflow: auto;
	}
	.mm-page
	{
		min-height: 1000px;
		position: fixed;
	}
	.mm-menu
	{
		height: auto;
		min-height: 1000px;
		overflow: auto;
		overflow-x: hidden;
		position: relative;
		left: auto;
		top: auto;
	}
	> .mm-panel
	{
		position: relative;
		height: auto;
		display: none;
		left: 0;
		
		&.mm-current
		{
			display: block;
		}
	}
}

@media (max-height: 667px) {
	ul[data-depth="0"].mm-list > li:not(.my-account) > a, ul[data-depth="0"].mm-list > li:not(.my-account) > span {
		height: 45px;
		line-height: 50px;
	}
}
