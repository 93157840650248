body.search-not-found {
  background: #fff;

  .searched-for {
    display: none;
  }
    #wrapper {
      background: #fff;
      h1.page-title {
        display: none;
      }
      #left-column {
        display: none;
      }

      #content-wrapper {
        width: auto;
        text-align: center;
        margin: 0 auto;
        padding-top: 45px;

        .page-not-found {
          border: none;
          padding-bottom: 100px;
          h4 {
            color: #2a527e;
            font-size: 36px;
            line-height: 0.89;
            font-weight: 500;
          }

          p {
            font-size: 14px;
            line-height: 3.57;
            color: #666;
          }

          #search_widget_none {
            form {
              max-width: 700px;
              margin: 0 auto;
              border: 1px solid #e9e9e9;
              border-radius: 19px;
            }

            .search-input {
              color: #2a527e;
              width: 100%;
              line-height: 36px;
              padding: 0 0 4px 20px;
              border-radius: 18px;
              margin: 0;
              min-height: 36px;
              font-size: 13px;
            }

            button {
              background: #fff;
              margin-top: 2px;
              position: absolute;
              border: none;
              top: 6px;
              right: 0.125rem;
              color: white;
              border-radius: 50%;
              padding: 3px 3px;
              width: 28px;
              height: 28px;
            }

            .search-icon {
              background: url('../img/icons/search-icon.svg') no-repeat;
              width: 16px;
              height: 16px;
              background-color: #fff;
            }

            .close-icon {
              background: url('../img/icons/close-icon.svg') no-repeat;
              width: 16px;
              height: 16px;
              background-color: #fff;
              background-size: cover;
              display: inline-block;
              position: absolute;
              top: 13px;
              right: 40px;
            }
          }
        }
      }

  }

  @media(max-width: 768px) {
    background: #f6f6f6;
    .mm-page {
      background: #f6f6f6;

      #wrapper {
        background: #f6f6f6;

        #content-wrapper {
          text-align: left;

          .page-not-found {
            background: #f6f6f6;

            h4 {
              font-size: 18px;
              text-transform: uppercase;
              line-height: 1.56;
              font-weight: bold;
            }

            p {
              font-size: 14px;
              line-height: 1.57
            }

            #search_widget_none {
              button {
                bottom: 6px;
                background-color: #f6f6f6;
              }

              .search-icon {
                background-color: #f6f6f6;
              }

              .search-input {
                background: #f6f6f6;
                border: solid 1px #a0a0a0;
              }
            }
          }
        }
      }
    }
  }
}