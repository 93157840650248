@mixin search-box {
  form {
    position: relative;
    input[type=text] {
      &:focus {
        color: $gray-darker;
        background: white;
        outline: none;
        + button .search {
          color: white;
        }
      }
      border: none;
      padding: 8px 10px 8px 20px;
      padding-right: 40px !important;
      min-width: 255px;
      background: white;
      color: $gray;
      border-radius: 17px;
      input::placeholder {
        color: $gray;
        text-indent: 0;
      }
    }
    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: 0.3125rem;
      right: 8px;
      color: $placeholder-color;

      .search:hover {
        color: white;
      }
      .search {
        font-size: 22px;
      }
      .search:focus {
        outline: none;
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-light {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

// Responsive visibility utilities

@each $bp in map-keys($grid-breakpoints) {
  .hidden-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      display: none !important;
    }
  }
  .hidden-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      display: none !important;
    }
  }
}
