.quiz-title {
  text-align: center;
  margin-bottom: 50px;
}

#quiz {
  .container {
    margin: 50px 0;
  }

  .alert-warning {
    margin-bottom: 30px;
    text-align: center;
  }
  .alert.alert-success a {
    color: #f19520;
  }

  .questions-wrapper {
    width: 70%;
    margin: auto;

    .question {
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 0.8px;
      position: relative;
      padding-bottom: 10px;
      color: #000000;
    }

    .question::after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: solid 2px #07adcb;
    }
  }
}